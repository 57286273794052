<!-- 面包屑 -->
<template>
  <div class="max_width">
    <el-breadcrumb separator-class="el-icon-arrow-right"
                   style="line-height:50px;">
      <el-breadcrumb-item v-for="(item,i) in crumbs"
                          :to="item.to"
                          :key='i'>{{item.text}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    crumbs: Array,
  },
  data () {
    return {
      // crumbs: [{ text: '首页', to: { path: '/' } }, { text: '资讯列表', to: { path: '/zixunlist' } }, { text: '资讯详情' }],
    };
  },

  components: {},

  computed: {},

  mounted () {
    console.log('面包屑');
    // session 设置值
    // sessionStorage.setItem('web_crumbs', JSON.stringify(test));
    // session 获取值
    // sessionStorage.getItem('web_crumbs');
    // session 删除值
    // sessionStorage.removeItem('key');
  },

  methods: {},
};

</script>
<style scoped>
</style>
