<!-- 驻会党建 -->
<template>
  <div class="main_area">
    <Head />
    <Breadcrumb :crumbs="crumbs" />
    <div style="flex: 1" class="max_width">
      <div class="zhdj_area">
        <div class="zhdj_area_left">
          <div>
            <router-link
              v-for="(info, j) in zixun_data"
              class="zixun_list_item"
              :key="j"
              :to="'/dangjianzixundetail?id=' + info.id"
            >
              <div v-show="info.img" class="zixun_list_item_img_box">
                <el-image :src="info.img" fit="contain"></el-image>
              </div>
              <div class="zixun_list_item_text_box">
                <div class="zixun_list_item_text_box_titel">
                  {{ info.title }}
                </div>
                <div class="zixun_list_item_text_box_text">{{ info.text }}</div>
                <div class="zixun_list_item_text_box_bottom">
                  <div class="zixun_list_item_text_box_bottom_time">
                    发布日期：{{ info.fabu_time }}
                  </div>
                  <div class="zixun_list_item_text_box_bottom_read">
                    {{ info.readnum }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="zhdj_area_right">
          <!-- <div class="index_title">党支部成员</div>
          <div>
            <el-popover placement="left"
                        width="740"
                        trigger="hover">
              <div>
                <el-image :src="require('@/assets/dangjian.jpeg')"
                          fit="contain"></el-image>
              </div>
              <el-image slot="reference"
                        style="height: 200px;margin-top: 30px;"
                        :src="require('@/assets/dangjian.jpeg')"
                        fit="contain"></el-image>
            </el-popover>
          </div> -->
          <div class="zhdj_area_right_name" v-for="(item, i) in names" :key="i">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from '@/components/Head.vue';
import Footer from '@/components/Footer.vue';
import RightFixBox from '@/components/RightFixBox.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  data () {
    return {
      crumbs: [{ text: '首页', to: { path: '/' } }, { text: '驻会党建' }],
      names: [], // '刘锡津', '王书伟(书记)', '刘峪升', '张履福', '刘全国', '田龙'
      zixun_data: [],
      // {
      //   id: 2,
      //   img: '',
      //   title: '会员 | 广东省民族管弦乐学会2021年度第二批新会员名单',
      //   text: '为了提升学会服务品质，加强学术研讨氛围，回馈广大会员和民乐从业者，自即日起，广东省民族管弦乐学会会员均可报名申领学会主编的图书资料一部（书单详见下文），申领者可在文末留言区按如下方式留言：书稿名称+姓名+会员证号+收件地址+联系电话。邮件通过顺丰快递，费用自理。',
      //   fabu_time: '2021-03-30',
      //   readnum: 231,
      // }, {
      //   id: 2,
      //   img: require('@/assets/dangjianimg2.jpg'),
      //   title: '广东省民族管弦乐学会 第五届北京竹笛邀请赛暨首届北京箫邀请赛 入围决赛名单查询',
      //   text: '广东省民族管弦乐学会第五届北京竹笛邀请赛暨首届北京箫邀请赛决赛于2020年8月15日至8月22日在《中国民乐》APP线上进行，请点击下方链接或者扫描下方二维码，输入“姓名”或者“证件号码”查询是否入围决赛。',
      //   fabu_time: '2021-03-30',
      //   readnum: 3936,
      // }, {
      //   id: 2,
      //   img: require('@/assets/dangjianimg1.jpg'),
      //   title: '广东省民族管弦乐学会 第五届北京竹笛邀请赛暨首届北京箫邀请赛 入围决赛名单查询',
      //   text: '广东省民族管弦乐学会第五届北京竹笛邀请赛暨首届北京箫邀请赛决赛于2020年8月15日至8月22日在《中国民乐》APP线上进行，请点击下方链接或者扫描下方二维码，输入“姓名”或者“证件号码”查询是否入围决赛。',
      //   fabu_time: '2021-03-30',
      //   readnum: 3936,
      // }
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
    Breadcrumb,
  },

  computed: {},

  mounted () {
    this.getdata();
  },

  methods: {
    // TODO 获取党建资讯
    getdata () {
      const that = this;
      this.$axios.post('/ziXun/getZiXun', {
        typeid: 7,
        page: 1,
        size: 10,
      }).then((res) => {
        console.log('获取资讯返回', res);
        if (res.code === 200) {
          for (let j = res.data.data.length - 1; j >= 0; j -= 1) {
            res.data.data[j].text = res.data.data[j].jianjie;
          }
          that.zixun_data = res.data.data;
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
  },
};

</script>
<style scoped>
</style>
