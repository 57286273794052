<!-- 学会简介 -->
<template>
  <div class="main_area">

    <Head />
    <Breadcrumb :crumbs="crumbs" />
    <div style="flex:1;"
         class="max_width">
      <div style="width: 1200px;box-sizing: border-box;box-shadow: 0 0 10px #d9e4ec;border-radius: 10px;padding:40px;margin:30px auto;">
        <div class="xhgk_title_box">
          <div class="xhgk_title">广东省民族管弦乐学会简介</div>
        </div>
        <div style="margin-top:10px;font-size:14px;">China Nationalities Orchestra Society (CNOS)</div>
        <div style="margin-top:40px;overflow:hidden;">
          <img v-if="img"
               class="ma wid100"
               :src="img" />
          <div v-for="(item,i) in content"
               :key='i'>
            <div class="xhjj_text">
              {{item}}
            </div>
          </div>
          <!-- <el-input type="textarea"
                    autosize
                    v-model="content">
          </el-input> -->
        </div>
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from '@/components/Head.vue';
import Footer from '@/components/Footer.vue';
import RightFixBox from '@/components/RightFixBox.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  data () {
    return {
      crumbs: [{ text: '首页', to: { path: '/' } }, { text: '广东省民族管弦乐学会简介' }],
      // eslint-disable-next-line max-len
      content: [],
      img: '',
      // require('@/assets/learn_photo.jpg')
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
    Breadcrumb,
  },

  computed: {},

  mounted () {
    this.getdata();
    this.getimg();
  },

  methods: {
    // TODO 获取数据
    getdata () {
      const that = this;
      that.$axios.post('/webshezhi/get', { id: 1 }).then((res) => {
        console.log('获取数据返回', res);
        if (res.code === 200) {
          that.content = JSON.parse(res.data.content).split('\n');
          console.log(that.content);
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    getimg () {
      const that = this;
      that.$axios.post('/webshezhi/get', { id: 4 }).then((res) => {
        console.log('获取数据返回', res);
        if (res.code === 200) {
          that.img = res.data.content;
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
  },
};

</script>
<style scoped>
</style>
