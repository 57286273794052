<!-- 网站顶部 -->
<template>
  <div class="web_head">
    <!-- <img alt="Vue logo"
         style="width:100vw;height:6vw;position:absolute;left:0;top:0;z-index:-1;"
         src="../assets/logo_bg.png"> -->
    <div class="head_top">
      <div class="head_top_text max_width">
        <div style="color: #999; position: absolute; left: 0; top: 0">
          欢迎访问
          <span style="width: 5px"></span>
          广东省民族管弦乐学会官网
        </div>
        <div style="position: absolute; right: 0; top: 0">
          <router-link to="/" style="color: #333; text-decoration: none"
            >登录</router-link
          >
          |
          <router-link to="/" style="color: #333; text-decoration: none"
            >注册</router-link
          >
        </div>
      </div>
    </div>
    <div class="max_width head_middle" style="overflow: hidden">
      <div style="float: left">
        <el-image
          style="width: 480px; height: 120px"
          :src="require('@/assets/logo.jpg')"
          fit="contain"
        ></el-image>
      </div>
      <div style="float: right">
        <div style="margin-top: 30px">
          <el-input
            placeholder="请输入内容"
            style="width: 400px"
            @change="dosearch"
            v-model="kwd"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <div
          style="
            line-height: 30px;
            font-size: 12px;
            text-align: left;
            padding-left: 5px;
          "
        >
          <span style="color: rgba(114, 109, 68, 0.5)">热搜榜：</span>
          <span style="margin: 0 5px">明日之星</span>
          <span style="margin: 0 5px">展演活动</span>
          <span style="margin: 0 5px">研修班</span>
        </div>
      </div>
    </div>
    <div style="background-color: white">
      <el-menu
        :default-active="activeIndex"
        mode="horizontal"
        style="text-align: center"
        class="max_width"
        router
      >
        <el-menu-item index="/">网站首页</el-menu-item>
        <el-submenu index="2">
          <template slot="title">学会概况</template>
          <el-menu-item index="/xuehuijianjie">学会简介</el-menu-item>
          <el-menu-item index="/huizhangjianjie">历任会长简介</el-menu-item>
          <el-menu-item index="/xianrenlingdao">现任领导简介</el-menu-item>
          <el-menu-item index="/changwulishimingdan">常务理事简介</el-menu-item>
          <el-menu-item index="/lishimingdan">理事名单</el-menu-item>
          <el-menu-item index="/zhuhuidangjian">驻会党建工作</el-menu-item>
          <el-menu-item index="/zhuhuirenyuan">驻会工作人员</el-menu-item>
          <el-menu-item index="/yuedongzhonghua"
            >《乐动中华》在线预览</el-menu-item
          >
        </el-submenu>
        <el-submenu index="3" popper-class="index_nav_area">
          <template slot="title">分支机构</template>
          <el-menu-item class="index_nav_item" index="/zhuanjiaweiyuanhui"
            >专家委员会</el-menu-item
          >
          <div v-for="(item, i) in fzjg_data" :key="i">
            <el-menu-item class="index_nav_item" :index="item.to">{{
              item.name
            }}</el-menu-item>
          </div>
          <!-- <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=2">古筝专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=3">古琴专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">胡琴专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">竹笛专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">唢呐专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">箜篌专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">创作委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">扬琴专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">笙专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">柳琴专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">乐器改革制造专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">三弦专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">陶笛艺术委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">理论评论委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">打击乐专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">琵琶专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">阮专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">指挥专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">葫芦丝巴乌专业委员会</el-menu-item>
          <el-menu-item class="index_nav_item"
                        index="/otherweiyuanhui?id=4">普及民族音乐艺术委员会</el-menu-item> -->
        </el-submenu>
        <el-menu-item index="/xueshuyanjiu">学术研究</el-menu-item>
        <el-menu-item index="/huiyuanzhuanqu">会员专区</el-menu-item>
        <!-- <el-menu-item index="/minyuekaoji">民乐考级</el-menu-item>
        <el-menu-item index="/yanxiubaoming">研修报名</el-menu-item>
        <el-menu-item index="/minyuedasai">民乐大赛</el-menu-item>
        <el-menu-item index="/yuepujieyue">乐谱借阅</el-menu-item>
        <el-menu-item index="/minyuebao">中国民乐报</el-menu-item> -->
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   activeIndex: String,
  // },
  data () {
    return {
      kwd: '',
      activeIndex: '',
      fzjg_data: [],
    };
  },

  components: {},

  computed: {},

  mounted () {
    console.log(this.$route.path);
    this.activeIndex = this.$route.path;
    this.getfenzhidata();
  },

  methods: {
    // TODO 获取分支机构数据
    getfenzhidata () {
      const that = this;
      that.$axios.post('/fenzhi/getFenzhiname', {}).then((res) => {
        console.log('分支机构数据', res);
        if (res.code === 200) {
          const temp = [];
          res.data.forEach((ele) => {
            temp.push({
              name: ele.name,
              to: `/otherweiyuanhui?id=${ele.id}`,
            });
          });
          that.fzjg_data = temp;
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // 关键字查找
    dosearch () {
      if (this.kwd) {
        console.log('关键字查找', this.kwd);
      } else {
        this.$message.info('请输入内容');
      }
    },
  },
};

</script>
<style scoped>
</style>
