<template>
  <div class="main_area">
    <!-- <img alt="Vue logo"
         src="../assets/logo.jpg"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->

    <Head />
    <!-- activeIndex="/"  -->
    <div style="flex: 1">
      <!-- 轮播图 -->
      <div>
        <el-carousel height="38.5vw">
          <el-carousel-item v-for="(item, i) in lb_data" :key="i">
            <router-link :to="item.to">
              <el-image
                style="width: 100vw"
                :src="item.img"
                fit="contain"
              ></el-image>
            </router-link>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 资讯推荐 -->
      <div class="max_width" style="position: relative">
        <el-tabs v-model="zx_activeName" @tab-click="changezixun">
          <el-tab-pane
            v-for="(item, i) in zxtypes"
            :key="i"
            :label="item.label"
            :name="item.name"
          >
            <div class="max_width" style="position: relative; height: 350px">
              <div
                style="
                  height: 350px;
                  width: 446px;
                  position: absolute;
                  left: 0;
                  top: 0;
                "
              >
                <div v-for="(zx, j) in item.infodata" :key="j">
                  <router-link :to="'/zixundetail?id=' + zx.id" v-show="j == 0">
                    <el-image
                      class="index_zx_one_img"
                      :src="zx.img"
                      fit="contain"
                    >
                      <div slot="error">
                        <el-image
                          class="index_zx_one_img"
                          :src="require('@/assets/zx_img.png')"
                          fit="contain"
                        >
                        </el-image>
                      </div>
                    </el-image>
                  </router-link>
                </div>
              </div>
              <div
                style="position: absolute; left: 446px; top: 0; margin: 0 20px"
              >
                <router-link
                  v-for="(zx, j) in item.infodata"
                  :to="'/zixundetail?id=' + zx.id"
                  style="color: #333; text-decoration: none"
                  :key="j"
                >
                  <div v-if="j == 0" class="index_zx_one_area">
                    <div class="index_zx_one_left">
                      <div>
                        <div style="font-size: 30px; text-align: center">
                          {{ zx.day }}
                        </div>
                        <div style="margin-top: 5px">
                          {{ zx.year }}/{{ zx.month }}
                        </div>
                      </div>
                    </div>
                    <div class="index_zx_one_right_area">
                      <div class="index_zx_one_right_title">
                        {{ zx.title }}
                      </div>
                      <div class="index_zx_one_right_text">
                        {{ zx.text }}
                      </div>
                    </div>
                  </div>
                  <div class="index_zx_other_area" v-else>
                    <span style="margin-right: 10px"
                      >{{ zx.year }}/{{ zx.month }}/{{ zx.day }}
                    </span>
                    {{ zx.title }}
                  </div>
                </router-link>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div
          style="
            position: absolute;
            right: 0;
            top: 0;
            line-height: 40px;
            font-size: 14px;
          "
        >
          <router-link to="/" style="text-decoration: none">更多</router-link>
        </div>
      </div>
      <!-- 民乐报 -->
      <!-- <div class="max_width">
        <router-link to="/minyuebao">
          <el-image style="width:100%"
                    :src="mybz_img"
                    fit="contain"></el-image>
        </router-link>
      </div> -->
      <!-- 功能服务 -->
      <div class="max_width">
        <el-tabs v-model="gn_activeName">
          <el-tab-pane label="民乐大赛" name="0">
            <div class="max_width" style="overflow: hidden">
              <a
                :href="item.url"
                v-for="(item, i) in kj_infodata"
                class="index_kj_item"
                :key="i"
              >
                <div class="index_kj_item_title">{{ item.title }}</div>
                <div style="margin: 0 auto; margin-top: 20px">
                  <el-image
                    :src="require('@/assets/kj_icon.png')"
                    fit="contain"
                  ></el-image>
                </div>
                <div class="index_kj_item_text">{{ item.text }}</div>
                <div style="text-align: left">
                  <el-image :src="item.icon" fit="contain"></el-image>
                </div>
                <div class="index_kj_item_btn">点击进入</div>
              </a>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="研修报名"
                       name="1">
            <div class="max_width"
                 style="overflow:hidden;">
              <div v-for="(item,i) in yx_data"
                   class="index_yx_area"
                   :key="i">
                <div class="index_yx_area_left">
                  <el-image :src="item.img"
                            class="index_yx_area_left_img"
                            fit="contain"></el-image>
                  <div v-show="today_text>item.end_date"
                       style="position:absolute;left:0;top:0;z-index:1;background-color:rgba(0,0,0,0.5);width:220px;height:158px;">
                    <el-image style="height:158px;"
                              fit="contain"
                              :src="require('@/assets/end_icon.png')"></el-image>
                  </div>
                </div>
                <div class="index_yx_area_right">
                  <div class="index_yx_area_right_title">
                    <span>新</span>
                    <span>({{item.type}})</span>
                    <span>{{item.title}}</span>
                  </div>
                  <div class="index_yx_area_right_text">{{item.text}}</div>
                  <div style="height:16px;overflow:hidden;line-height:16px;font-size:12px;text-align:left;margin:10px 0;">
                    <div style="float:left;">
                      <el-image :src="require('@/assets/date.png')"
                                style="width:14px;height:16px;"
                                fit="contain"></el-image>
                    </div>
                    <div style="float:left;margin-left:5px;color: #7f7974;">报名时间:{{item.start_date}}至{{item.end_date}}</div>
                  </div>
                  <div style="overflow:hidden;">
                    <div class="index_yx_area_right_btn">立即报名</div>
                    <div style="width:60%;margin-left:2%;float:left;overflow:hidden;line-height: 28px;">
                      <div style="float:left;">
                        <el-image :src="require('@/assets/yiwen.png')"
                                  style="width:16px;height:28px;"
                                  fit="contain"></el-image>
                      </div>
                      <div class="index_yx_area_right_bottom_text">成绩查询</div>
                      <div style="float:left;">
                        <el-image :src="require('@/assets/fabiao.png')"
                                  style="width:16px;height:28px;"
                                  fit="contain"></el-image>
                      </div>
                      <div class="index_yx_area_right_bottom_text">提交论文</div>
                      <div style="float:left;">
                        <el-image :src="require('@/assets/seach2.png')"
                                  style="width:16px;height:28px;"
                                  fit="contain"></el-image>
                      </div>
                      <div class="index_yx_area_right_bottom_text">报名查询</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane> -->
          <!-- <el-tab-pane label="会员专区"
                       name="2">
            <div class="max_width"
                 style="float:left;">
              <div v-for="(item,i) in hy_data"
                   class="index_hy_area_item"
                   :style="{width:(i==5)?'15%':'17%'}"
                   :key='i'>
                <div style="float:left;width:160px;box-sizing: border-box;border: 1px solid #eee;">
                  <div class="index_hy_area_item_title">{{item.title}}</div>
                  <el-image class="index_hy_area_item_img1"
                            :src="require('@/assets/kj_icon.png')"
                            fit="contain"></el-image>
                  <el-image :src="item.icon"
                            class="index_hy_area_item_img2"
                            fit="contain"></el-image>
                  <div class="index_hy_area_item_text">{{item.text}}</div>
                  <div v-show="i==5"
                       class="index_hy_area_item_btn">在线申请</div>
                </div>
                <div v-show="i!=5"
                     style="float:right;margin-right:-10px;">
                  <el-image style="width:20px;height:278px"
                            :src="require('@/assets/member_arrow.png')"
                            fit="contain"></el-image>
                </div>
              </div>
            </div>
          </el-tab-pane> -->
          <!-- <el-tab-pane label="民乐大赛"
                       name="3">
            <div class="max_width"
                 style="overflow:hidden;">
              <router-link to="/test"
                           v-for="(item,i) in ds_data"
                           class="index_ds_area_item"
                           :key='i'>
                <div>
                  <el-image style="height:190px;"
                            :src="item.img"
                            fit="cover"></el-image>
                </div>
                <div class="index_ds_area_item_title">{{item.title}}</div>
                <div class="index_ds_area_item_text ">
                  <div style="float:left;">
                    <el-image :src="require('@/assets/date.png')"
                              class="index_ds_area_item_icon"
                              fit="contain"></el-image>
                  </div>
                  <span style="margin-left:3px;">报名时间：{{item.bm_start_date}}至{{item.bm_end_date}}</span>
                </div>
                <div class="index_ds_area_item_text ">
                  <div style="float:left;">
                    <el-image :src="require('@/assets/add_icon.png')"
                              class="index_ds_area_item_icon"
                              fit="contain"></el-image>
                  </div>
                  <span style="margin-left:3px;">活动地点：{{item.addr}}</span>
                </div>
                <div style="text-align:center;margin:5px auto;">
                  <el-button type="info"
                             :disabled="today_text>item.bm_end_date"
                             style="width:200px;">
                    <span v-if="today_text>item.bm_end_date">报名截止</span>
                    <span v-else>点击报名</span>
                  </el-button>
                </div>
              </router-link>
            </div>
          </el-tab-pane> -->
          <!-- <el-tab-pane label="乐谱借阅"
                       name="4">
            <div class="max_width"
                 style="overflow:hidden;">
              <div v-for="(item,i) in yp_data"
                   class="index_yp_area_item"
                   :key='i'>
                <div class="index_yp_area_item_img_box">
                  <el-image :src="require('@/assets/乐谱借阅1.png')"
                            class="index_yp_area_item_img"
                            fit="contain"></el-image>
                  <div class="index_yp_area_item_img_box_text">编号：{{item.bianhao}}</div>
                </div>
                <div class="index_yp_area_item_icon_box1"
                     style="margin-top:10px;">
                  <div style="float:left;">
                    <el-image :src="require('@/assets/biaoqian.png')"
                              class="index_yp_area_item_icon1"
                              fit="contain"></el-image>
                  </div>
                  <span class="index_yp_area_item_text1">乐谱名称：{{item.name}}</span>
                </div>
                <div class="index_yp_area_item_icon_box1"
                     style="margin-bottom:10px;">
                  <div style="float:left;">
                    <el-image :src="require('@/assets/date.png')"
                              class="index_yp_area_item_icon1"
                              fit="contain"></el-image>
                  </div>
                  <span class="index_yp_area_item_text1">作曲：{{item.author}}</span>
                </div>
                <div class="index_yp_area_item_btn">借阅</div>
                <div style="overflow:hidden;margin-top:10px;border-top:1px solid #e5e5e5;">
                  <div style="border-right:1px solid #e5e5e5;"
                       class="index_yp_area_item_bottom_box">
                    <div class="index_yp_area_item_bottom_icon">
                      <el-image :src="require('@/assets/dianji.png')"
                                class="index_yp_area_item_icon2"
                                fit="contain"></el-image>
                    </div>
                    <span class="index_yp_area_item_bottom_text">点击量：{{item.num_dj}}</span>
                  </div>
                  <div class="index_yp_area_item_bottom_box">
                    <div class="index_yp_area_item_bottom_icon">
                      <el-image :src="require('@/assets/user.png')"
                                class="index_yp_area_item_icon2"
                                fit="contain"></el-image>
                    </div>
                    <span>借阅量：{{item.num_jy}}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane> -->
        </el-tabs>
      </div>
      <div
        class="max_width"
        style="
          background: linear-gradient(#fff, #f1f1f1);
          margin: 10px auto;
          position: relative;
        "
      >
        <!-- 粒子特效 -->
        <vue-particles
          color="#dedede"
          :particleOpacity="0.7"
          :particlesNumber="160"
          shapeType="circle"
          :particleSize="6"
          linesColor="#dedede"
          :linesWidth="1"
          :lineLinked="true"
          :lineOpacity="0.4"
          :linesDistance="150"
          :moveSpeed="3"
          :hoverEffect="true"
          hoverMode="repulse"
          :clickEffect="true"
          clickMode="repulse"
        >
        </vue-particles>
        <!-- 参数说明
        color: String类型。默认'#dedede'。粒子颜色。
        particleOpacity: Number类型。默认0.7。粒子透明度。
        particlesNumber: Number类型。默认80。粒子数量。
        shapeType: String类型。默认'circle'。可用的粒子外观类型有："circle","edge","triangle", "polygon","star"。
        particleSize: Number类型。默认80。单个粒子大小。
        linesColor: String类型。默认'#dedede'。线条颜色。
        linesWidth: Number类型。默认1。线条宽度。
        lineLinked: 布尔类型。默认true。连接线是否可用。
        lineOpacity: Number类型。默认0.4。线条透明度。
        linesDistance: Number类型。默认150。线条距离。
        moveSpeed: Number类型。默认3。粒子运动速度。
        hoverEffect: 布尔类型。默认true。是否有hover特效。
        hoverMode: String类型。默认true。可用的hover模式有: "grab", "repulse", "bubble"。
        clickEffect: 布尔类型。默认true。是否有click特效。
        clickMode: String类型。默认true。可用的click模式有: "push", "remove", "repulse", "bubble"。 -->
        <div class="index_fzjg_area">
          <ul>
            <li v-for="(item, i) in fzjg_data" :key="i">
              <router-link :to="item.to">
                <el-image :src="item.img" fit="contain"></el-image>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="max_width">
        <router-link to="/xueshuyanjiu">
          <el-image
            :src="require('@/assets/艺术发展研究中心.jpg')"
            style="width: 100%"
            fit="contain"
          ></el-image>
        </router-link>
      </div>
    </div>
    <!-- <UploadMP4 @upload_res="uploadres" /> -->
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import Head from '@/components/Head.vue';
import Footer from '@/components/Footer.vue';
import RightFixBox from '@/components/RightFixBox.vue';
// import UploadMP4 from '@/components/UploadMP4.vue';

export default {
  name: 'Index',
  components: {
    // HelloWorld,
    Head,
    Footer,
    RightFixBox,
    // UploadMP4,
  },
  data () {
    return {
      lb_data: [], // 轮播图数据
      // {
      //   img: require('@/assets/轮播图1.jpg'), to: '/zixundetail?id=1',
      // }
      zx_activeName: '0',
      zxtypes: [],
      mybz_img: require('@/assets/民乐报.png'),
      gn_activeName: '0',
      kj_infodata: [
        // {
        //   title: '考级工作概览',
        //   icon: require('@/assets/kj_icon1.png'),
        //   text: '全面了解民乐考级',
        //   url: '',
        // },
        {
          title: '比赛报名入口', // '考级报名入口',
          icon: require('@/assets/kj_icon2.png'),
          text: '参赛者在线报名并可输入报名相关凭证信息进行报名在线查询',
          url: '/dasaibaoming',
        },
        {
          title: '比赛成绩查询',
          icon: require('@/assets/kj_icon3.png'),
          text: '参赛者可输入报名相关凭证信息进行考试成绩在线查询',
          url: '/dasaichengji',
        },
        // , {
        //   title: '承办单位登录',
        //   icon: require('@/assets/kj_icon1.png'),
        //   text: '中国民族管弦学会承办单位管理平台-承办单位登录入口',
        //   url: '',
        // }
      ], // 考级数据
      today_text: this.$moment().format('YYYY-MM-DD'), // 获取今天日期
      yx_data: [], // 研修报名数据
      // {
      //   img: require('@/assets/研修班1.jpg'),
      //   type: '大连',
      //   title: '2020年古筝考级指导教师研修班',
      //   text: '为更好地弘扬古筝艺术，培养具有一定演奏水平和教学能力的古筝老师，以满足广大古筝爱好者的需求，2020年11月由广东省民族管弦乐学会主办，辽宁省民民族管弦乐学会承办的古筝考级知道教师研修班。此次研修班将聘请国内知名古筝演奏家和音乐教育家授课，通过集中研修学习，使学习与在古筝的演奏、教学和理论水平规范梳理并有所提高。',
      //   start_date: '2020-10-15',
      //   end_date: '2020-11-15',
      // }, {
      //   img: require('@/assets/研修班2.jpg'),
      //   type: '箜篌',
      //   title: '2020年考级指导教师研修班',
      //   text: '为更好地弘扬箜篌艺术，培养具有一定演奏水平和教学能力的箜篌教师，以满足广大箜篌爱好者的需求，广东省民族管弦乐学会定于2020年8月举办全国箜篌考级指导教师研修班。此次研修班聘请国内知名箜篌演奏家和音乐教育家授课，让学员能通过集中研修学习，在箜篌的演奏、教学和理论水平有较大的提高。为更好地弘扬箜篌艺术，培养具有一定演奏水平和教学能力的箜篌教师，以满足广大箜篌爱好者的需求，广东省民族管弦乐学会定于2020年8月举办全国箜篌考级指导教师研修班。此次研修班聘请国内知名箜篌演奏家和音乐教育家授课，让学员能通过集中研修学习，在箜篌的演奏、教学和理论水平有较大的提高。',
      //   start_date: '2020-08-12',
      //   end_date: '2020-09-21',
      // }, {
      //   img: require('@/assets/研修班3.png'),
      //   type: '陶笛',
      //   title: '2020年考级指导教师研修班',
      //   text: '为更好地弘扬陶笛艺术，培养具有一定演奏水平和教学能力的陶笛教师，以满足广大陶笛爱好者的需求，广东省民族管弦乐学会定于2020年8月举办全国陶笛考级指导教师研修班。此次研修班聘请国内知名陶笛演奏家和音乐教育家授课，让学员能通过集中研修学习，在陶笛的演奏、教学和理论水平有较大的提高。',
      //   start_date: '2020-08-09',
      //   end_date: '2020-08-10',
      // }, {
      //   img: require('@/assets/研修班4.png'),
      //   type: '北京',
      //   title: '2019年葫芦丝考级指导教师研修班',
      //   text: '为满足广大葫芦丝爱好者的需求，培养具有规范教学能力的葫芦丝教师，广东省民族管弦乐学会定于2019年12月9日——12月13日，举办“葫芦丝考级指导教师研修班”。此次培训班将聘请国内知名葫芦丝演奏家和教育家授课，让学员通过集中学习，在葫芦丝的演奏、教学和理论水平等方面得到充分的提高。',
      //   start_date: '2019-10-25',
      //   end_date: '2019-12-13',
      // }
      hy_data: [], // 会员专区数据
      // {
      //   title: '提交会员申请',
      //   icon: require('@/assets/member_1.png'),
      //   text: '用户在线填写申请请认真填写 已经填写无法修改',
      // }, {
      //   title: '工作人员审核',
      //   icon: require('@/assets/member_2.png'),
      //   text: '申请提交后工作人员会在规定时间内进行信息审核',
      // }, {
      //   title: '会员状态查询',
      //   icon: require('@/assets/member_3.png'),
      //   text: '信息审核后，用户可进行会员状态查询',
      // }, {
      //   title: '会费支付',
      //   icon: require('@/assets/member_4.png'),
      //   text: '用户会员信息审核通过后，用户在线支付会员会费',
      // }, {
      //   title: '制证邮寄',
      //   icon: require('@/assets/member_5.png'),
      //   text: '会员支付会费后工作人员制作会员证并邮寄给用户',
      // }, {
      //   title: '成为会员',
      //   icon: require('@/assets/member_6.png'),
      //   text: '',
      // }
      ds_data: [], // 民乐大赛数据
      // {
      //   img: require('@/assets/民乐大赛1.png'),
      //   title: '第四届“明日之星”展演 【民族打击乐组】',
      //   bm_start_date: '2019-04-15',
      //   bm_end_date: '2019-06-21',
      //   addr: '地点另行通知',
      // }, {
      //   img: require('@/assets/民乐大赛2.png'),
      //   title: '第四届“明日之星”展演 【拉弦乐组】',
      //   bm_start_date: '2019-04-15',
      //   bm_end_date: '2019-06-21',
      //   addr: '地点另行通知',
      // }, {
      //   img: require('@/assets/民乐大赛3.png'),
      //   title: '第四届“明日之星”展演 【弹拨乐组】',
      //   bm_start_date: '2019-04-15',
      //   bm_end_date: '2019-06-21',
      //   addr: '地点另行通知',
      // }, {
      //   img: require('@/assets/民乐大赛4.png'),
      //   title: '第四届“明日之星”展演 【吹管乐组】',
      //   bm_start_date: '2019-04-15',
      //   bm_end_date: '2019-06-21',
      //   addr: '地点另行通知',
      // }
      yp_data: [], // 乐谱数据
      // {
      //   img: require('@/assets/乐谱借阅1.png'),
      //   bianhao: 1905292556,
      //   name: '醒狮·龙舟',
      //   author: '李复斌',
      //   num_dj: '3380', // 点击量
      //   num_jy: '19', // 借阅量
      // }, {
      //   img: require('@/assets/乐谱借阅2.png'),
      //   bianhao: 1905297749,
      //   name: '山·火',
      //   author: '董锦汉',
      //   num_dj: '2956', // 点击量
      //   num_jy: '8', // 借阅量
      // }, {
      //   img: require('@/assets/乐谱借阅3.png'),
      //   bianhao: 1905299667,
      //   name: '太阳·玄鸟',
      //   author: '李复斌',
      //   num_dj: '2838', // 点击量
      //   num_jy: '9', // 借阅量
      // }, {
      //   img: require('@/assets/乐谱借阅4.png'),
      //   bianhao: 1905291751,
      //   name: '梨园之花',
      //   author: '王瑞',
      //   num_dj: '2311', // 点击量
      //   num_jy: '6', // 借阅量
      // }
      fzjg_data: [], // 分支机构数据
      // {
      //   img: require('@/assets/branch_8.png'), // 创作
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_13.png'), // 古筝
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_11.png'), // 扬琴
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_18.png'), // 打击乐
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_7.png'), // 古琴
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_16.png'), // 笙
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_1.png'), // 琵琶
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_6.png'), // 胡琴
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_14.png'), // 柳琴
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_4.png'), // 阮
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_12.png'), // 竹笛
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_19.png'), // 乐器改革制作
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_5.png'), // 指挥
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_3.png'), // 唢呐
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_9.png'), // 三弦
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_10.png'), // 葫芦丝巴乌
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_2.png'), // 箜篌
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_17.png'), // 陶笛
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_20.png'), // 普及民族音乐艺术
      //   to: '/otherweiyuanhui?id=4',
      // }, {
      //   img: require('@/assets/branch_15.png'), // 理论评论
      //   to: '/otherweiyuanhui?id=4',
      // }
    };
  },
  mounted () {
    this.getlb_data();
    this.getzxtype();
    this.getfenzhidata();
  },
  methods: {
    // TODO 获取分支机构数据
    getfenzhidata () {
      const that = this;
      that.$axios.post('/fenzhi/getFenzhiimg', {}).then((res) => {
        console.log('分支机构数据', res);
        if (res.code === 200) {
          const temp = [];
          res.data.forEach((ele) => {
            if (ele.img) {
              temp.push({
                img: ele.img,
                to: `/otherweiyuanhui?id=${ele.id}`,
              });
            }
          });
          that.fzjg_data = temp;
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO tab点击事件
    changezixun (tab) {
      console.log('切换资讯类型点击事件', tab);
      this.getzxdata(tab.index);
    },
    // TODO 上传事件
    uploadres (res) {
      console.log('上传组件返回值', res);
    },
    // TODO 获取轮播图数据
    getlb_data () {
      const that = this;
      this.$axios.get('/lunbotu/getLunbotu').then((res) => {
        console.log('获取轮播图列表返回', res);
        if (res.code === 200) {
          for (let i = res.data.length - 1; i >= 0; i -= 1) {
            res.data[i].to = `/zixundetail?id=${res.data[i].zixunid}`;
          }
          that.lb_data = res.data;
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 获取资讯类别 只要前三个
    getzxtype () {
      const that = this;
      this.$axios.get('/ziXunType/getZiXunType').then((res) => {
        console.log('获取资讯类别', res);
        if (res.code === 200) {
          const zxtypes_temp = [];
          // if (res.data.length > 3) {
          //   for (let i = 0; i < 3; i += 1) {
          //     zxtypes_temp.push({
          //       label: res.data[i].name,
          //       name: `${res.data[i].id}`,
          //       infodata: [],
          //     });
          //   }
          // } else {
          for (let i = 0; i < res.data.length; i += 1) {
            zxtypes_temp.push({
              label: res.data[i].name,
              name: `${res.data[i].id}`,
              infodata: [],
            });
          }
          // }
          if (zxtypes_temp) {
            that.zxtypes = zxtypes_temp;
            that.zx_activeName = zxtypes_temp[0].name;
            // 获取第一个资讯类别的明细数据
            that.getzxdata(0);
          }
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 获取某类资讯数据
    getzxdata (i) {
      console.log('获取某类资讯', i);
      const that = this;
      this.$axios.post('/ziXun/getZiXun', {
        typeid: parseInt(that.zxtypes[i].name, 10),
        page: 1,
        size: 5,
      }).then((res) => {
        console.log('获取资讯返回', res);
        if (res.code === 200) {
          for (let j = res.data.data.length - 1; j >= 0; j -= 1) {
            res.data.data[j].time = res.data.data[j].fabu_time;
            res.data.data[j].text = res.data.data[j].jianjie;
            res.data.data[j].year = res.data.data[j].fabu_time.substring(0, 4);
            res.data.data[j].month = res.data.data[j].fabu_time.substring(5, 7);
            res.data.data[j].day = res.data.data[j].fabu_time.substring(8);
          }
          that.zxtypes[i].infodata = res.data.data;
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
  },
};
</script>
