<!-- 网站底部 -->
<template>
  <div style="background-color: #f0f0f0">
    <div
      style="height: 84px; line-height: 84px; border-bottom: 1px solid #e4e4e4"
    >
      <div class="max_width" style="overflow: hidden">
        <div
          v-for="(item, i) in hrefs"
          style="
            float: left;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          "
          :style="{ width: href_width }"
          :key="i"
        >
          <router-link
            :to="item.href"
            style="color: #333; text-decoration: none"
            >{{ item.text }}</router-link
          >
        </div>
        <el-image
          style="width: 30px; float: right; margin-top: 30px"
          :src="require('@/assets/caidan.png')"
          fit="contain"
        ></el-image>
      </div>
    </div>
    <div style="margin: 10px auto; text-align: center">
      <el-image
        style="width: 400px"
        :src="require('@/assets/logo.png')"
        fit="contain"
      ></el-image>
    </div>
    <div style="color: #999; font-size: 14px; line-height: 24px">
      <div>地址：{{ addr }}</div>
      <div style="margin-top: 10px; margin-bottom: 20px">
        <span>版权所有</span>
        <span style="padding: 0 5px">{{ bq_info }}</span>
        <span>备案号：</span>
        <a
          :href="bah_herf"
          target="_blank"
          style="text-decoration: none; color: black"
          >{{ bah }}</a
        >
      </div>
      <div style="width: 300px; margin: 0 auto; padding: 20px 0">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011302003091"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img :src="require('@/assets/备案图标.png')" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            粤公网安备 44011302003091号
          </p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      bah: '粤ICP备2021082346号',
      bah_herf: 'https://beian.miit.gov.cn',
      bq_info: 'Copyright(C)2021-2021 广东省民族管弦乐学会',
      addr: '广州市番禺区大石街富山北路港润商业广场三楼',
      hrefs: [{
        href: '/',
        text: '网站首页',
      }, {
        href: '/xuehuijianjie',
        text: '学会概况',
      }, {
        href: '/xueshuyanjiu',
        text: '学术研究',
      }, {
        href: '/huiyuanzhuanqu',
        text: '会员专区',
      },
        //  {
        //   href: '/minyuekaoji',
        //   text: '民乐考级',
        // }, {
        //   href: '/yanxiubaoming',
        //   text: '研修报名',
        // }, {
        //   href: '/minyuedasai',
        //   text: '民乐大赛',
        // }, {
        //   href: '/yuepujieyue',
        //   text: '乐谱借阅',
        // }, {
        //   href: '/minyuebao',
        //   text: '中国民乐报',
        // }
      ],
      href_width: '',
    };
  },

  components: {},

  computed: {},

  mounted () {
    this.href_width = `${Number((1 / (this.hrefs.length + 1)) * 100).toFixed(1)}%`;
    console.log(this.href_width);
  },

  methods: {},
};

</script>
<style scoped>
</style>
