<!-- 乐动中华在线预览 -->
<template>
  <div class="main_area">

    <Head />
    <div class="max_width"
         style="flex:1;">
      <div style="width:800px;background-color:red;margin:30px auto;">
        <turn :width="manualWidth"
              ref="turn"
              :height="manualHeight"
              :data="manuals"
              @change="handleTurnChange"
              @tab="handelToggleControl"
              @turning="handleTurning"
              @prev="handleTurnPrev"
              @next="handleTurnNext"></turn>
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from '@/components/Head.vue';
import Footer from '@/components/Footer.vue';
import RightFixBox from '@/components/RightFixBox.vue';
import turn from 'vue-flip-page';

export default {
  data () {
    return {
      manuals: [],
      // { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_03.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_04.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_05.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_06.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_07.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_08.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_09.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_10.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_11.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_12.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_13.png') }, { picture_image: require('@/assets/book_yuedongzhonghua/内页(1)_14.png') }
      manualWidth: 800,
      manualHeight: 800,
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
    turn,
  },

  computed: {},

  mounted () {
    this.getdata();
  },

  methods: {
    // TODO 获取采集书籍数据
    getdata () {
      const that = this;
      this.$axios.post('/bookcontent/getlist', {
        page: 1,
        size: 20,
        is_del: 0,
        bookid: 1,
      }).then((res) => {
        console.log('获取书籍内容返回', res);
        if (res.code === 200) {
          for (let i = res.data.data.length - 1; i >= 0; i -= 1) {
            res.data.data[i].picture_image = res.data.data[i].img;
          }
          that.manuals = res.data.data;
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    handleTurnChange (e) {
      console.log('页面变化', e);
    },
    handelToggleControl (e) {
      console.log('ToggleControl', e);
    },
    handleTurning (e) {
      console.log('翻页中', e);
    },
    handleTurnPrev (e) {
      console.log('返回上一页', e);
    },
    handleTurnNext (e) {
      console.log('下一页', e);
    },
  },
};

</script>
<style >
</style>
