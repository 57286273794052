<!-- 专家委员会 -->
<template>
  <div class="main_area">

    <Head />
    <div style="flex:1;width:100vw;"
         class="zjwyh_bg">
      <div class="max_width zjwyh_main">
        <div class="zjwyh_main_title_box">
          <div class="zjwyh_main_title">专家委员会</div>
        </div>
        <div style="margin-top:20px;">
          <div style="text-indent: 2em;line-height: 1.8em;padding: 0 20px;padding-bottom: 20px;border-bottom: 1px dashed #ddd;">
            <ul class="zjwyh_personnel_list">
              <li v-for="(item,i) in zj_data"
                  style="margin-top: 5px;width: 25%;"
                  :key='i'>
                <el-image v-show="item.img"
                          :src="item.img"
                          style="height:325px;    -webkit-justify-content: center;justify-content: center;-webkit-align-items: center;align-items: center;display: -webkit-flex;display: flex;overflow: hidden;"
                          fit="contain"></el-image>
                <div class="zjwyh_personnel_item">
                  <div style="font-weight: bold;font-size: 18px;">{{item.name}}</div>
                  <div style="color: #ef7f5a;margin-top: 5px;">{{item.zhiwei}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from '@/components/Head.vue';
import Footer from '@/components/Footer.vue';
import RightFixBox from '@/components/RightFixBox.vue';

export default {
  data () {
    return {
      zj_data: [],
      // {
      //   img: require('@/assets/zhuanjiaweiyuanhui/1.png'),
      //   name: '刘锡津',
      //   zhiwei: '主任委员',
      // }, {
      //   img: require('@/assets/zhuanjiaweiyuanhui/2.png'),
      //   name: '张殿英',
      //   zhiwei: '副主任委员',
      // }, {
      //   img: require('@/assets/zhuanjiaweiyuanhui/3.png'),
      //   name: '乔建中',
      //   zhiwei: '副主任委员',
      // }, {
      //   img: require('@/assets/zhuanjiaweiyuanhui/4.png'),
      //   name: '王次炤',
      //   zhiwei: '专家委员',
      // }, {
      //   img: require('@/assets/zhuanjiaweiyuanhui/5.png'),
      //   name: '刘再生',
      //   zhiwei: '专家委员',
      // }, {
      //   img: require('@/assets/zhuanjiaweiyuanhui/6.png'),
      //   name: '闫拓时',
      //   zhiwei: '专家委员',
      // }, {
      //   img: require('@/assets/zhuanjiaweiyuanhui/7.png'),
      //   name: '李吉提',
      //   zhiwei: '专家委员',
      // }
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
  },

  computed: {},

  mounted () {
    this.getdata();
  },

  methods: {
    compare (property) {
      return (a, b) => {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    },
    getdata () {
      const that = this;
      that.$axios.post('/webshezhi/get', { id: 7 }).then((res) => {
        console.log('获取数据返回', res);
        if (res.code === 200 && res.data) {
          if (res.data.content) {
            const data_arr = JSON.parse(res.data.content);
            that.zj_data = data_arr.sort(that.compare('xuhao'));
          }
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
  },
};

</script>
<style scoped>
</style>
