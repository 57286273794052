<!-- 系统设置页 -->
<template>
  <div>
    <div></div>
    <el-tabs v-model="activate"
             @tab-click="changedata">
      <el-tab-pane label="学会简介"
                   name="1">
        <el-input type="textarea"
                  autosize
                  placeholder="请输入内容"
                  v-model="xhinfo">
        </el-input>
        <el-button @click="save">保存</el-button>
      </el-tab-pane>
      <el-tab-pane label="常务理事简介"
                   name="2">
        <el-input type="textarea"
                  autosize
                  placeholder="请输入内容"
                  v-model="cwlsmd">
        </el-input>
        <el-button @click="save">保存</el-button>
      </el-tab-pane>
      <el-tab-pane label="理事名单"
                   style="text-align:left;"
                   name="3">
        <el-tag :key="i"
                v-for="(tag,i) in lsmd"
                closable
                :disable-transitions="false"
                @close="handleClose(i)">
          {{tag}}
        </el-tag>
        <el-input class="input-new-tag"
                  v-if="inputVisible"
                  v-model="inputValue"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm">
        </el-input>
        <el-button v-else
                   class="button-new-tag"
                   size="small"
                   @click="showInput">添加</el-button>
        <el-button size="small"
                   @click="save">保存</el-button>
      </el-tab-pane>
      <el-tab-pane label="学会简介中合影"
                   style="text-align:left;"
                   name="4">
        <el-upload :action="uploadurl"
                   :headers="myHeaders"
                   name="file"
                   class="avatar-uploader"
                   :show-file-list="false"
                   :before-upload="beforeUpload"
                   :on-success="uploadsucess">
          <img v-if="content"
               :src="content"
               style="width:80vw;"
               fit="contain">
          <i v-else
             class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-button size="small"
                   @click="save">保存</el-button>
      </el-tab-pane>
      <el-tab-pane label="专家委员会"
                   style="text-align:left;"
                   name="7">
        <div v-for="(item,i) in zjdata"
             style="text-align:right;"
             :key='i'>
          <el-form :model="item"
                   label-width="100px"
                   style="text-align:left;">
            <el-form-item label="姓名">
              <el-input v-model="item.name"
                        placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="职位">
              <el-input v-model="item.zhiwei"
                        placeholder="请输入职位"></el-input>
            </el-form-item>
            <el-form-item label="照片">
              <el-upload :action="uploadurl"
                         :headers="myHeaders"
                         name="file"
                         class="avatar-uploader"
                         :show-file-list="false"
                         :before-upload="beforeUpload"
                         :on-success="(res) => { return lduploadsucess(res, i); }">
                <img v-if="item.img"
                     :src="item.img"
                     class="avatar">
                <i v-else
                   class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="序号">
              <el-input v-model="item.xuhao"
                        type="number"
                        placeholder="请输入排序 小的在前"></el-input>
            </el-form-item>
            <el-form-item label="删除">
              <el-button type="danger"
                         @click="delld(i)">删除</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary"
                   @click="addld">新 加</el-button>
        <el-button @click="save">保存</el-button>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activate: '1',
      content: '',
      xhinfo: '',
      cwlsmd: '',
      lsmd: [],
      inputVisible: false,
      inputValue: '',
      myHeaders: { jwttoken: sessionStorage.getItem('jwt_token') },
      uploadurl: 'https://www.gdnos.com.cn/api/test/uploadimg',
      fileList: [],
      zjdata: [],
    };
  },

  components: {},

  computed: {},

  mounted () {
    this.getdata();
  },

  methods: {
    // TODO 新加领导
    addld () {
      this.zjdata.push({
        name: '',
        zhiwei: '',
        img: '',
        xuhao: 0,
      });
    },
    // TODO 删除领导数据
    delld (i) {
      this.zjdata.splice(i, 1);
    },
    // TODO 专家图片上传成功
    lduploadsucess (res, i) {
      console.log('上传接口返回', res);
      console.log('索引', i);
      if (res.code === 200) {
        this.zjdata[i].img = `https://www.gdnos.com.cn/${res.msg}`;
        console.log('新建内容', this.lddata[i]);
      } else {
        this.$message.error('图片上传失败');
      }
    },
    // TODO 图片上传成功
    uploadsucess (res, file, fileList) {
      console.log('上传接口返回', res);
      console.log('文件', file);
      console.log('文件列表', fileList);
      if (res.code === 200) {
        this.content = `https://www.gdnos.com.cn/${res.msg}`;
      } else {
        this.$message.error('图片上传失败');
      }
    },
    // TODO 上传图片之前开启loading
    beforeUpload (file) {
      // this.uillUpdateImg = true
      this.$message.info('图片上传中，请等待。。。');
      const that = this;
      return new Promise((resolve) => {
        const reader = new FileReader();
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          let { width } = image;
          let { height } = image;
          // 等比缩放第一种 宽或高超过1280，进行等比例缩放
          let rate = image.width / image.height;
          if (width > 1280 || height > 1280) {
            width = rate > 1 ? 1280 : 1280 * rate;
            height = rate < 1 ? 1280 : 1280 / rate;
          }

          // 等比缩放第二种 宽或高超过1280，等比缩放
          rate = 1280 / width > 1;
          const tate = 1280 / height > 1;
          if (!rate) {
            const product = 1280 / width;
            width = Math.floor((width * product) * 100) / 100;
            height = Math.floor((height * product) * 100) / 100;
          } else if (!tate) {
            const product = 1280 / height;
            width = Math.floor((width * product) * 100) / 100;
            height = Math.floor((height * product) * 100) / 100;
          }
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type);
          const blobData = that.dataURItoBlob(dataUrl, file.type);
          resolve(blobData);
        };
        reader.onload = ((e) => { image.src = e.target.result; });
        reader.readAsDataURL(file);
      });
    },
    // TODO 压缩图片
    dataURItoBlob (dataURI, type) {
      const binary = atob(dataURI.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type });
    },
    handleClose (i) {
      this.lsmd.splice(i, 1);
    },

    showInput () {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm () {
      const { inputValue } = this;
      if (inputValue) {
        this.lsmd.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    // TODO 处理数据库数据显示到页面
    makedata () {
      if (this.content) {
        if (this.activate === '1') {
          console.log('学会简介', this.content);
          this.xhinfo = JSON.parse(this.content);
          return;
        }
        if (this.activate === '2') {
          console.log('常务理事简介', this.content);
          this.cwlsmd = JSON.parse(this.content);
          return;
        }
        if (this.activate === '3') {
          console.log('理事名单');
          this.lsmd = JSON.parse(this.content);
        }
        if (this.activate === '4') {
          console.log('学会合影');
        }
        if (this.activate === '7') {
          if (this.content) {
            this.zjdata = JSON.parse(this.content);
          } else {
            this.zjdata = [];
          }
        }
      }
    },
    // TODO 保存
    save () {
      if (this.activate === '1') {
        console.log('学会简介', this.xhinfo);
        if (!this.xhinfo) {
          this.$message.info('未设置学会简介');
          return;
        }
        this.content = JSON.stringify(this.xhinfo);
        this.setdata();
        return;
      }
      if (this.activate === '2') {
        console.log('常务理事名单');
        if (!this.cwlsmd) {
          this.$message.info('未设置常务理事简介');
          return;
        }
        this.content = JSON.stringify(this.cwlsmd);
        this.setdata();
        return;
      }
      if (this.activate === '3') {
        console.log('理事名单');
        if (!this.lsmd) {
          this.$message.info('未设置理事名单');
          return;
        }
        this.content = JSON.stringify(this.lsmd);
        this.setdata();
      }
      if (this.activate === '4') {
        console.log('理事名单');
        if (!this.content) {
          this.$message.info('未设置学会合影图片');
          return;
        }
        this.setdata();
      }
      if (this.activate === '7') {
        let obj = '';
        if (this.zjdata) {
          obj = JSON.stringify(this.zjdata);
        }
        const that = this;
        that.$axios.post('/webshezhi/set', {
          id: 7,
          name: '专家委员会',
          content: obj,
        }).then((res) => {
          console.log('设置数据返回', res);
          if (res.code === 200) {
            that.$message.success('设置成功');
          } else {
            that.$message.error('设置失败');
          }
        }).catch((err) => {
          console.log('请求失败', err);
        });
      }
    },
    // TODO 切换菜单
    changedata () {
      console.log(this.activate);
      this.getdata();
    },
    // TODO 设置数据
    setdata () {
      let text = '';
      if (this.activate === '1') {
        text = '学会简介';
      }
      if (this.activate === '2') {
        text = '常务理事名单';
      }
      if (this.activate === '3') {
        text = '理事名单';
      }
      if (this.activate === '4') {
        text = '学会合影';
      }
      if (this.activate === '7') {
        text = '专家委员会';
      }
      const that = this;
      that.$axios.post('/webshezhi/set', {
        id: parseInt(this.activate, 10),
        name: text,
        content: this.content,
      }).then((res) => {
        console.log('设置数据返回', res);
        if (res.code === 200) {
          that.$message.success('设置成功');
        } else {
          that.$message.error('设置失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 获取数据
    getdata () {
      const that = this;
      that.$axios.post('/webshezhi/get', { id: parseInt(this.activate, 10) }).then((res) => {
        console.log('获取数据返回', res);
        if (res.code === 200) {
          that.content = res.data.content;
          that.makedata();
        } else {
          that.$message.error('获取失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
  },
};

</script>
<style >
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
