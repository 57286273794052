<!-- 右边浮动边框 -->
<template>
  <div class="main_right_fix_box">
    <!-- 微信区 -->
    <!-- <el-popover placement="left"
                popper-class="fix_left_area"
                trigger="hover">
      <div>
        <div>扫码关注</div>
        <div v-for="(item,i) in wxdata"
             :key="i">
          <div style="width:100px;height:100px;">
            <el-image style="width:100px;height:100px;background-color:white;"
                      :src="item.qrimg"
                      fit="contain"></el-image>
          </div>
          <div>{{item.text}}</div>
        </div>
      </div>
      <div class="main_right_fix_item"
           slot="reference">
        <el-image class="main_right_fix_img"
                  :src="require('@/assets/微信_wechat.png')"
                  fit="contain"></el-image>
      </div>
    </el-popover> -->
    <!-- 客服 -->
    <el-popover placement="left"
                popper-class="fix_left_area"
                trigger="hover">
      <div>
        <div>咨询客服</div>
        <div v-for="(item,i) in kfdata"
             :key="i">
          <div style="width:100px;height:100px;">
            <el-image style="width:100px;height:100px;"
                      :src="item.qrimg"
                      fit="contain"></el-image>
          </div>
          <!-- <div>{{item.text}}</div> -->
        </div>
      </div>
      <div class="main_right_fix_item"
           slot="reference">
        <el-image class="main_right_fix_img"
                  :src="require('@/assets/耳机_客服_headset-one.png')"
                  fit="contain"></el-image>
      </div>
    </el-popover>
    <!-- 个人中心 -->
    <!-- <el-popover placement="left"
                popper-class="fix_left_area"
                trigger="hover">
      <router-link to="/"
                   style="color:white;text-decoration: none;">
        个人中心
      </router-link>
      <div class="main_right_fix_item"
           slot="reference">
        <el-image class="main_right_fix_img"
                  :src="require('@/assets/用户_user.png')"
                  fit="contain"></el-image>
      </div>
    </el-popover> -->
    <!-- 我的关注 -->
    <!-- <el-popover placement="left"
                popper-class="fix_left_area"
                trigger="hover">
      <router-link to="/"
                   style="color:white;text-decoration: none;">
        我的关注
      </router-link>
      <div class="main_right_fix_item"
           slot="reference">
        <el-image class="main_right_fix_img"
                  :src="require('@/assets/喜欢_关注_like.png')"
                  fit="contain"></el-image>
      </div>
    </el-popover> -->
    <!-- 常见问题 -->
    <!-- <el-popover placement="left"
                popper-class="fix_left_area"
                trigger="hover">
      <router-link to="/question"
                   style="color:white;text-decoration: none;">
        常见问题
      </router-link>
      <div class="main_right_fix_item"
           slot="reference">
        <el-image class="main_right_fix_img"
                  :src="require('@/assets/沟通_communication.png')"
                  fit="contain"></el-image>
      </div>
    </el-popover> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      wxdata: [{
        qrimg: require('@/assets/qrimg.png'),
        text: '中国民乐',
      }, {
        qrimg: require('@/assets/qrimg.png'),
        text: '中国民乐考级',
      }, {
        qrimg: require('@/assets/qrimg.png'),
        text: '中国民乐资讯',
      }, {
        qrimg: require('@/assets/qrimg.png'),
        text: '中国民乐评论',
      }],
      kfdata: [{
        qrimg: require('@/assets/sijucai.jpg'),
        text: '咨询客服',
      }],
    };
  },

  components: {},

  computed: {},

  mounted () { },

  methods: {},
};

</script>
<style scoped>
</style>
