<!-- 研修报名 -->
<template>
  <div>研修报名</div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},

  computed: {},

  mounted () { },

  methods: {},
};

</script>
<style scoped>
</style>
