<!-- 民乐大赛 -->
<template>
  <div>
    <el-tabs v-model="activate"
             @tab-click="changedata">
      <el-tab-pane label="参赛项目"
                   style="text-align:left;"
                   name="5">
        <el-tag :key="i"
                v-for="(tag,i) in csxm"
                style="margin:5px;"
                closable
                :disable-transitions="false"
                @close="handleClose(i)">
          {{tag}}
        </el-tag>
        <el-input class="input-new-tag"
                  v-if="inputVisible"
                  v-model="inputValue"
                  ref="saveTagInput"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm">
        </el-input>
        <el-button v-else
                   class="button-new-tag"
                   @click="showInput">添加</el-button>
        <el-button @click="save">保存</el-button>
      </el-tab-pane>
      <el-tab-pane label="参赛组别"
                   style="text-align:left;"
                   name="6">
        <div v-for="(item,i) in zubie"
             style="margin:10px auto;"
             :key='i'>
          <div>
            <el-input v-model="item.text"
                      style="width:300px;"
                      placeholder="大组别名称"></el-input>
            <el-button @click="zubieadd(i)">增加</el-button>
            <el-button @click="zubiedel(i)">删除</el-button>
            <el-button @click="zubieadd_c(i,-1)">增加子组别</el-button>
          </div>
          <div v-for="(item1,j) in item.data"
               style="margin:5px;margin-left:50px;"
               :key='j'>
            <el-input v-model="item1.text"
                      style="width:300px;"
                      placeholder="组别名称"></el-input>
            <el-button @click="zubieadd_c(i,j)">增加</el-button>
            <el-button @click="zubiedel_c(i,j)">删除</el-button>
          </div>
        </div>
        <el-button @click="zubieadd(-1)">增加</el-button>
        <el-button @click="save">保存</el-button>
      </el-tab-pane>
      <el-tab-pane label="大赛管理"
                   style="text-align:left;"
                   name="7">
        <div>
          <div style="text-align:left;margin:5px 0;">
            <el-button @click="addds"
                       type="primary">添加大赛</el-button>
            <el-select v-model="is_del"
                       style="margin:0 5px;"
                       @change="statuschange"
                       placeholder="请选择">
              <el-option v-for="(item,i) in statuslist"
                         :key="i"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
            <el-switch v-model="spcontrol"
                       @change="changeSPcontrol"
                       active-text="允许播放"
                       inactive-text="禁止播放">
            </el-switch>
          </div>
          <el-table :data="dasais"
                    stripe
                    border
                    style="width: 100%">
            <el-table-column prop="name"
                             align="center"
                             label="大赛名称"
                             width="180">
            </el-table-column>
            <el-table-column label="状态"
                             align="center"
                             width="180">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.is_del ===0 "
                        type="success">报名中</el-tag>
                <el-tag v-else
                        type="danger">停止报名</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="update_time"
                             align="center"
                             label="修改时间"
                             width="180">
            </el-table-column>
            <el-table-column label="操作"
                             align="center">
              <template slot-scope="scope">
                <el-button @click="getdsurl(scope.row)"
                           type="primary"
                           size="small">初赛赛场链接</el-button>
                <el-button @click="editds(scope.row)"
                           type="primary"
                           size="small">编辑</el-button>
                <el-button v-if="scope.row.is_del ===0 "
                           type="danger"
                           @click="delds(scope.row,1)"
                           size="small">停止报名</el-button>
                <el-button v-else
                           type="success"
                           @click="delds(scope.row,0)"
                           size="small">接受报名</el-button>
                <el-button type="primary"
                           @click="getfsurl(scope.row)"
                           size="small">决赛赛场链接</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-sizes="[20, 50, 100]"
                         :page-size="size"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

export default {
  name: 'adminIndex',
  data () {
    return {
      csxm: ['古筝', '琵琶', '二胡', '扬琴', '笛子', '高胡', '柳琴', '唢呐', '阮', '笙', '萧', '打击乐', '葫芦丝', '陶笛', '古琴', '箜篌', '马头琴', '组合', '其它'],
      zubie: [{
        text: '专业组',
        data: [{ text: '少年A组(12岁-14岁)' }, { text: '少年B组(15岁-17岁)' }, { text: '成年A组(18岁-22岁)' }, { text: '成年B组(23岁-49岁)' }],
      }, {
        text: '爱乐组',
        data: [{ text: '学前组(6岁以下)' }, { text: '少儿组(7岁-9岁)' }, { text: '少年A组(10岁-12岁)' }, { text: '少年B组(13岁-15岁)' }, { text: '少年C组(16岁-18岁)' }, { text: '成年A组(19岁-22岁)' }, { text: '成年B组(23岁-49岁)' }, { text: '老年组(50岁以上)' }],
      }],
      activate: '5',
      content: '',
      inputVisible: false,
      inputValue: '',
      myHeaders: { jwttoken: sessionStorage.getItem('jwt_token') },
      uploadurl: 'https://www.gdnos.com.cn/api/test/uploadimg',
      fileList: [],
      dasais: [],
      page: 1,
      total: 0,
      size: 20,
      is_del: 0,
      statuslist: [{ id: 0, name: '报名中' }, { id: 1, name: '停止报名' }],
      spcontrol: true,
    };
  },

  components: {},

  computed: {},

  mounted () {
    this.getdata();
    this.getspcontrol();
  },

  methods: {
    // 获取视频播放控制
    getspcontrol () {
      const that = this;
      that.$axios.post('/webshezhi/get', { id: 8 }).then((res) => {
        console.log('获取数据返回', res);
        if (res.code === 200) {
          if (res.data.content === '1') {
            that.spcontrol = true;
          } else {
            that.spcontrol = false;
          }
        } else {
          that.$message.error('获取失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // 修改视频播放允许
    changeSPcontrol (val) {
      console.log(val);
      let sign = '0';
      if (val) {
        sign = '1';
      }
      const that = this;
      that.$axios.post('/webshezhi/set', {
        id: 8,
        name: '视频播放控制',
        content: sign,
      }).then((res) => {
        console.log('设置数据返回', res);
        if (res.code === 200) {
          that.$message.success('设置成功');
        } else {
          that.$message.error('设置失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 选择大赛信息 输入赛场信息 生成链接
    getdsurl (val) {
      console.log('大赛信息', val);
      console.log(window.location.origin);
      this.$prompt('请输入赛场', '获取初赛赛场链接', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        value = value.replace(/\s/g, '');
        if (value) {
          console.log('链接', `${window.location.origin}/saichang?id=${val.id}&saichang=${value}`);
          this.$message({
            message: `赛场链接:${window.location.origin}/saichang?id=${val.id}&saichang=${value}`,
            duration: 0,
            showClose: true,
          });
        }
      }).catch(() => {
      });
    },
    getfsurl (val) {
      console.log('大赛信息', val);
      console.log(window.location.host);
      this.$prompt('请输入赛场', '获取决赛赛场链接', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        value = value.replace(/\s/g, '');
        if (value) {
          console.log('链接', `${window.location.host}/fusaisaichang?id=${val.id}&saichang=${value}`);
          this.$message({
            message: `赛场链接:${window.location.host}/fusaisaichang?id=${val.id}&saichang=${value}`,
            duration: 0,
            showClose: true,
          });
        }
      }).catch(() => {
      });
    },
    // TODO 翻页
    handleCurrentChange (val) {
      this.page = val;
      this.getdsdata();
    },
    // TODO 修改每页数量
    handleSizeChange (val) {
      this.size = val;
      this.page = 1;
      this.getdsdata();
    },
    // TODO 添加大赛
    addds () {
      console.log('新加大赛');
      const that = this;
      this.$prompt('请输入大赛名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        that.$axios.post('/dasai/add', {
          name: value,
        }).then((res) => {
          console.log('新增大赛返回', res);
          if (res.code === 200) {
            that.page = 1;
            that.is_del = 0;
            that.getdsdata();
          } else {
            that.$message({
              type: 'warning',
              message: '新增失败!',
            });
          }
        }).catch((err) => {
          console.log('请求失败', err);
        });
      }).catch(() => {
      });
    },
    // TODO 编辑大赛名称
    editds (data) {
      console.log('编辑大赛名称', data);
      const that = this;
      this.$prompt('请输入大赛名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: JSON.parse(JSON.stringify(data.name)),
      }).then(({ value }) => {
        if (value === data.name) {
          that.$message({
            type: 'info',
            message: '内容无修改',
          });
        } else {
          that.$confirm('此操作将修改数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            const obj = JSON.parse(JSON.stringify(data));
            obj.name = value;
            that.$axios.post('/dasai/update', obj).then((res) => {
              console.log('编辑返回', res);
              if (res.code === 200) {
                that.getdsdata();
              } else {
                that.$message({
                  type: 'warning',
                  message: '编辑失败!',
                });
              }
            }).catch((err) => {
              console.log('请求失败', err);
            });
          }).catch(() => {
          });
        }
      }).catch(() => {
      });
    },
    // TODO 停用启用大赛
    delds (data, sign) {
      console.log(`启停用大赛${sign}`, data);
      const that = this;
      const obj = JSON.parse(JSON.stringify(data));
      obj.is_del = sign;
      that.$axios.post('/dasai/del', obj).then((res) => {
        console.log('启停用大赛返回', res);
        that.getdsdata();
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 状态改变
    statuschange () {
      console.log('状态改变', this.is_del);
      this.page = 1;
      this.getdsdata();
    },
    // TODO 获取大赛数据
    getdsdata () {
      const that = this;
      that.$axios.post('/dasai/getlist', {
        page: this.page,
        size: this.size,
        is_del: this.is_del,
      }).then((res) => {
        console.log('大赛数据', res);
        if (res.code === 200) {
          that.dasais = res.data.data;
          that.total = res.data.count;
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 删除子组别
    zubiedel_c (i, j) {
      this.zubie[i].data.splice(j, 1);
    },
    // TODO 添加子组别
    zubieadd_c (i, j) {
      if (j === -1) {
        this.zubie[i].data.push({ text: '' });
      } else {
        this.zubie[i].data.splice(j + 1, 0, { text: '' });
      }
    },
    // TODO 删除大组别
    zubiedel (val) {
      this.zubie.splice(val, 1);
    },
    // TODO 添加大组别
    zubieadd (val) {
      console.log(val);
      if (val === -1) {
        this.zubie.push({
          text: '',
          data: [],
        });
      } else {
        this.zubie.splice(val + 1, 0, {
          text: '',
          data: [],
        });
      }
    },
    // TODO 图片上传成功
    uploadsucess (res, file, fileList) {
      console.log('上传接口返回', res);
      console.log('文件', file);
      console.log('文件列表', fileList);
      if (res.code === 200) {
        this.content = `https://www.gdnos.com.cn/${res.msg}`;
      } else {
        this.$message.error('图片上传失败');
      }
    },
    // TODO 上传图片之前开启loading
    beforeUpload (file) {
      // this.uillUpdateImg = true
      this.$message.info('图片上传中，请等待。。。');
      const that = this;
      return new Promise((resolve) => {
        const reader = new FileReader();
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          let { width } = image;
          let { height } = image;
          // 等比缩放第一种 宽或高超过1280，进行等比例缩放
          let rate = image.width / image.height;
          if (width > 1280 || height > 1280) {
            width = rate > 1 ? 1280 : 1280 * rate;
            height = rate < 1 ? 1280 : 1280 / rate;
          }

          // 等比缩放第二种 宽或高超过1280，等比缩放
          rate = 1280 / width > 1;
          const tate = 1280 / height > 1;
          if (!rate) {
            const product = 1280 / width;
            width = Math.floor((width * product) * 100) / 100;
            height = Math.floor((height * product) * 100) / 100;
          } else if (!tate) {
            const product = 1280 / height;
            width = Math.floor((width * product) * 100) / 100;
            height = Math.floor((height * product) * 100) / 100;
          }
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type);
          const blobData = that.dataURItoBlob(dataUrl, file.type);
          resolve(blobData);
        };
        reader.onload = ((e) => { image.src = e.target.result; });
        reader.readAsDataURL(file);
      });
    },
    // TODO 压缩图片
    dataURItoBlob (dataURI, type) {
      const binary = atob(dataURI.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type });
    },
    handleClose (i) {
      this.csxm.splice(i, 1);
    },
    showInput () {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm () {
      const { inputValue } = this;
      if (inputValue) {
        this.csxm.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    // TODO 处理数据库数据显示到页面
    makedata () {
      if (this.content) {
        if (this.activate === '5') {
          console.log('参赛项目', this.content);
          if (this.content) {
            this.csxm = JSON.parse(this.content);
          }
          return;
        }
        if (this.activate === '6') {
          console.log('参赛组别', this.content);
          if (this.content) {
            this.zubie = JSON.parse(this.content);
          }
        }
      }
    },
    // TODO 保存
    save () {
      if (this.activate === '5') {
        console.log('参赛项目', this.xhinfo);
        if (!this.csxm) {
          this.$message.info('未设置参赛项目');
          return;
        }
        this.content = JSON.stringify(this.csxm);
        this.setdata();
        return;
      }
      if (this.activate === '6') {
        console.log('参赛组别');
        if (!this.zubie) {
          this.$message.info('未设置参赛组别');
          return;
        }
        this.content = JSON.stringify(this.zubie);
        this.setdata();
      }
    },
    // TODO 切换菜单
    changedata () {
      console.log(this.activate);
      if (this.activate === '6' || this.activate === '5') {
        this.getdata();
      } else {
        console.log('获取大赛数据');
        this.getdsdata();
      }
    },
    // TODO 设置数据
    setdata () {
      let text = '';
      if (this.activate === '5') {
        text = '参赛项目';
      }
      if (this.activate === '6') {
        text = '参赛组别';
      }
      const that = this;
      that.$axios.post('/webshezhi/set', {
        id: parseInt(this.activate, 10),
        name: text,
        content: this.content,
      }).then((res) => {
        console.log('设置数据返回', res);
        if (res.code === 200) {
          that.$message.success('设置成功');
        } else {
          that.$message.error('设置失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 获取数据
    getdata () {
      const that = this;
      that.$axios.post('/webshezhi/get', { id: parseInt(this.activate, 10) }).then((res) => {
        console.log('获取数据返回', res);
        if (res.code === 200) {
          that.content = res.data.content;
          that.makedata();
        } else {
          that.$message.error('获取失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
  },
};

</script>
<style scoped>
</style>
