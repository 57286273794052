<!-- 会员专区 -->
<template>
  <div class="main_area">

    <Head />
    <!-- flex:1; -->
    <div style="width:100vw;"
         class="hyzq_bg">
      <div class="max_width"
           style="margin-top: 120px;overflow:hidden;width: 1100px;">
        <div class="hyzq_left">
          <div style="font-size: 18px;font-weight: bold;line-height: 18px;color:#fff;overflow:hidden;">
            <div style="width: 18px;height: 18px;background-color: #fff;float:left;"></div>
            <div style="float:left;margin-left:10px;">申请流程</div>
          </div>
          <el-image style="margin-top:30px;"
                    :src="require('@/assets/会员专区/member_step.png')"
                    fit="contain"></el-image>
        </div>
        <div class="hyzq_right"
             style="text-align:left;">
          <div style="padding-bottom: 10px;color: #c19c5c;border-bottom: 2px solid #c19c5c;padding-left: 10px;font-size:26px;">会员专区</div>
          <div style="padding:20px;">
            <div style="line-height: 2em;color: #bf8f3d;">为了适应学会不断发展的新形势、广泛团结全国各地的民乐工作者，共同弘扬优秀民族音乐文化；普及、发展和繁荣民族管弦艺术；为我国的文化大繁荣、大发展与精神文明建设贡献力量。学会决定本着严格条件、保证质量、积极发展的精神发展新会员。</div>
          </div>
          <div class="hyzq_hysq_btn_box">
            <div class="hyzq_hysq_btn">立即申请</div>
          </div>
          <div style="color: #999;text-align: center;margin-top:10px;font-size:12px;margin-bottom:20px;">登记后不可修改</div>
          <div class="hyzq_hycx_btn">会员查询</div>
          <div style="color: #999;margin-top: 10px;text-align:center;font-size: 12px;">可查询会员信息后进行支付</div>
          <div style="margin-top:30px;"
               class="hyzq_mamber_tip">
            <ul>
              <li @click="hytj_show=true">会员条件</li>
              <li @click="zysx_show=true">注意事项</li>
            </ul>
          </div>
        </div>
      </div>
      <el-dialog title="会员条件"
                 :visible.sync="hytj_show">
        <div style="text-align:left;font-size:16px;">
          由于本学会是民乐专业的学术团体，因此，要求入会的人员必须是从事民乐艺术的作曲家、理论家、指挥家、演奏家、教育家、编辑家、活动家、民族乐器制作家。在具体操作上，遵循以下几点：
          <p>（1） 凡具有中级以上职称的民乐工作者，一般都可以被批准入会；</p>
          <p>（2）四十岁以上的老民乐工作者，由于种种原因目前虽未被评上中级职称，但毕竟为我国民乐事业的建设和发展做过多年的贡献，有一定的艺术成就，在一个地区有一定的社会影响和声望，也可以被批准入会。</p>
          <p>（3）年轻的民乐工作者，凡获得过全国性民乐比赛一、二、三等奖及优秀奖的可以被批准入会；凡获得过省级民乐比赛一、二、三等奖的，也可以被批准入会；各大民乐团的乐队首席、独奏演员、声部首席或业务骨干，也可以被批准入会。</p>
        </div>
      </el-dialog>
      <el-dialog title="注意事项"
                 :visible.sync="zysx_show">
        <div style="text-align:left;font-size:16px;">
          <p>·1.对自愿入会者可通过“中国民族管弦学会”官网进入“会员中心”，点击“填报入会申请”选项进行会员报名（所需填写资料包括：个人基本信息、个人照片、身份证正方面图片、学历证书图片、职称证书图片），确认信息无误后点击提交，等待审核。</p>
          <p>·2.在线申请完成后，经学会负责会员发展的主管人员审核、签署意见，由秘书长签署批准，将结果反馈“会员查询”，审批通过学员，会根据提示完成缴费后，学会办公室人员负责办理入会登记、建档手续，并寄发会员证。</p>
          <p>·3.香港、澳门、台湾地区申请入会者，一经批准，均为本会的正式会员。外籍华乐人士申请入会者，只要符合条件，可以被批准为本会名誉会员。原国内会员出国留学、定居的，凡保留中国国籍的仍为本学会正式会员；一旦取得外国国籍，将自动转为名誉会员。</p>
          <p>
            ·4.非从事民乐专业的社会贤达人士，为本学会做出突出贡献者，自愿申请入会的，可被批准为荣誉会员。
          </p>
          <p>·5.请各地方民族管弦乐学会、本会的各位荣誉理事、名誉理事、常务理事、理事、会员，根据本通知的精神，积极发展新会员。
          </p>
          <p>.6.申请加入各专业委员会会员需按照各专业委员会具体要求提交申请。</p>
          <p>学会的通讯处：北京市海淀区西直门北大街58号金晖嘉园9号楼106室，邮编：100082</p>
          <p>会员事宜联系电话：010-82290054</p>
        </div>
      </el-dialog>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from '@/components/Head.vue';
import Footer from '@/components/Footer.vue';
import RightFixBox from '@/components/RightFixBox.vue';

export default {
  data () {
    return {
      hytj_show: false,
      zysx_show: false,
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
  },

  computed: {},

  mounted () { },

  methods: {},
};

</script>
<style scoped>
</style>
