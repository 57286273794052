<!-- 分支机构管理页面 -->
<template>
  <div>
    <div style="text-align:left;margin:5px 0;">
      <el-button type="primary"
                 style="margin:0 5px;"
                 @click="add">新加</el-button>
      <el-select v-model="is_del"
                 @change="changeis_del"
                 placeholder="请选择">
        <el-option v-for="(item,i) in statuslist"
                   :key="i"
                   :label="item.name"
                   :value="item.id">
        </el-option>
      </el-select>
    </div>
    <el-table :data="tableData"
              stripe
              border
              style="width: 100%;">
      <el-table-column prop="name"
                       align="center"
                       label="分支机构名称"
                       width="300">
      </el-table-column>
      <el-table-column prop="img"
                       align="center"
                       label="图标"
                       width="300">
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px"
                    :src="scope.row.img"
                    fit="contain"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="序号"
                       prop="xuhao"
                       align="center"
                       width="100">
      </el-table-column>
      <el-table-column prop="update_time"
                       align="center"
                       label="修改时间"
                       width="180">
      </el-table-column>
      <el-table-column label="操作"
                       align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)"
                     type="primary"
                     size="small">编辑</el-button>
          <el-button v-if="scope.row.is_del===0"
                     type="danger"
                     @click="del(scope.row,1)"
                     size="small">隐藏</el-button>
          <el-button v-else
                     type="success"
                     @click="del(scope.row,0)"
                     size="small">显示</el-button>
          <el-button @click="showld(scope.row)"
                     type="primary"
                     size="small">机构领导</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="page"
                   :page-sizes="[20, 50, 100]"
                   :page-size="size"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total">
    </el-pagination>
    <!-- 新建 -->
    <el-dialog title="新建"
               :visible.sync="addshow"
               width="80%">
      <el-form ref="addobj"
               :model="addobj"
               label-width="100px"
               style="text-align:left;">
        <el-form-item label="分支机构名称">
          <el-input v-model="addobj.name"
                    placeholder="请输入分支机构名称"></el-input>
        </el-form-item>
        <el-form-item label="分支机构图标">
          <el-upload :action="uploadurl"
                     :headers="myHeaders"
                     name="file"
                     class="avatar-uploader"
                     :show-file-list="false"
                     :before-upload="beforeUpload"
                     :on-success="uploadsucess">
            <img v-if="addobj.img"
                 :src="addobj.img"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="机构合影">
          <el-upload :action="uploadurl"
                     :headers="myHeaders"
                     name="file"
                     class="avatar-uploader"
                     :show-file-list="false"
                     :before-upload="beforeUpload"
                     :on-success="uploadsucess1">
            <img v-if="addobj.heying"
                 :src="addobj.heying"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="分支机构介绍">
          <el-input type="textarea"
                    :rows="3"
                    placeholder="请输入分支机构介绍"
                    v-model="addobj.jianshao">
          </el-input>
        </el-form-item>
        <el-form-item label="排序 小的在前">
          <el-input v-model="addobj.xuhao"
                    type="number"
                    placeholder="请输入排序 小的在前"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addshow = false">取 消</el-button>
        <el-button type="primary"
                   @click="doadd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="编辑"
               :visible.sync="editshow"
               width="80%">
      <el-form ref="editobj"
               :model="editobj"
               label-width="100px"
               style="text-align:left;">
        <el-form-item label="分支机构名称">
          <el-input v-model="editobj.name"
                    placeholder="请输入分支机构名称"></el-input>
        </el-form-item>
        <el-form-item label="分支机构图标">
          <el-upload :action="uploadurl"
                     :headers="myHeaders"
                     name="file"
                     class="avatar-uploader"
                     :show-file-list="false"
                     :before-upload="beforeUpload"
                     :on-success="uploadsucess">
            <img v-if="editobj.img"
                 :src="editobj.img"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="机构合影">
          <el-upload :action="uploadurl"
                     :headers="myHeaders"
                     name="file"
                     class="avatar-uploader"
                     :show-file-list="false"
                     :before-upload="beforeUpload"
                     :on-success="uploadsucess1">
            <img v-if="editobj.heying"
                 :src="editobj.heying"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="分支机构介绍">
          <el-input type="textarea"
                    :rows="3"
                    placeholder="请输入分支机构介绍"
                    v-model="editobj.jianshao">
          </el-input>
        </el-form-item>
        <el-form-item label="排序 小的在前">
          <el-input v-model="editobj.xuhao"
                    type="number"
                    placeholder="请输入排序 小的在前"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="editshow = false">取 消</el-button>
        <el-button type="primary"
                   @click="doedit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 机构领导管理 -->
    <el-dialog title="机构领导管理"
               :visible.sync="ldshow"
               width="80%">
      <div v-for="(item,i) in lddata"
           style="text-align:right;"
           :key='i'>
        <el-form :model="item"
                 label-width="100px"
                 style="text-align:left;">
          <el-form-item label="姓名">
            <el-input v-model="item.name"
                      placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="职位">
            <el-input v-model="item.zhiwei"
                      placeholder="请输入职位"></el-input>
          </el-form-item>
          <el-form-item label="照片">
            <el-upload :action="uploadurl"
                       :headers="myHeaders"
                       name="file"
                       class="avatar-uploader"
                       :show-file-list="false"
                       :before-upload="beforeUpload"
                       :on-success="(res) => { return lduploadsucess(res, i); }">
              <img v-if="item.img"
                   :src="item.img"
                   class="avatar">
              <i v-else
                 class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="序号">
            <el-input v-model="item.xuhao"
                      type="number"
                      placeholder="请输入排序 小的在前"></el-input>
          </el-form-item>
          <el-form-item label="删除">
            <el-button type="danger"
                       @click="delld(i)">删除</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="addld">新 加</el-button>
        <el-button @click="ldshow = false">取 消</el-button>
        <el-button type="primary"
                   @click="saveld">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      is_del: 0,
      statuslist: [{ id: 0, name: '显示' }, { id: 1, name: '隐藏' }],
      addshow: false,
      addobj: {},
      editshow: false,
      editobj: {},
      page: 1,
      size: 20,
      total: 0,
      myHeaders: { jwttoken: sessionStorage.getItem('jwt_token') },
      uploadurl: 'https://www.gdnos.com.cn/api/test/uploadimg',
      fileList: [],
      ldobj: {},
      lddata: [],
      ldshow: false,
    };
  },

  components: {},

  computed: {},

  mounted () {
    this.getdata();
  },

  methods: {
    // TODO 删除领导数据
    delld (i) {
      this.lddata.splice(i, 1);
    },
    // TODO 显示领导数据
    showld (val) {
      this.ldobj = JSON.parse(JSON.stringify(val));
      if (this.ldobj.lingdao) {
        this.lddata = JSON.parse(this.ldobj.lingdao);
      } else {
        this.lddata = [];
      }
      this.ldshow = true;
    },
    // TODO 新加领导
    addld () {
      this.lddata.push({
        name: '',
        zhiwei: '',
        img: '',
        xuhao: 0,
      });
    },
    // TODO 保存领导
    saveld () {
      if (this.lddata) {
        this.ldobj.lingdao = JSON.stringify(this.lddata);
      } else {
        this.ldobj.lingdao = '';
      }
      const that = this;
      that.$axios.post('/fenzhi/update', this.ldobj).then((res) => {
        console.log('修改领导数据返回', res);
        if (res.code === 200) {
          that.$message.success('保存成功');
          that.getdata();
          that.ldshow = false;
        } else {
          that.$message.info(res.msg);
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 领导图片上传成功
    lduploadsucess (res, i) {
      console.log('上传接口返回', res);
      console.log('索引', i);
      if (res.code === 200) {
        this.lddata[i].img = `https://www.gdnos.com.cn/${res.msg}`;
        console.log('新建内容', this.lddata[i]);
      } else {
        this.$message.error('图片上传失败');
      }
    },
    // TODO 隐藏显示书籍内容
    del (data, sign) {
      console.log(`隐藏显示内容${sign}`, data);
      const obj = JSON.parse(JSON.stringify(data));
      obj.is_del = sign;
      const that = this;
      that.$axios.post('/fenzhi/del', obj).then((res) => {
        console.log('内容显示状态返回', res);
        if (res.code === 200) {
          that.$message.success('显示状态修改成功');
          that.getdata();
        } else {
          that.$message.error('显示状态修改失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 显示隐藏变动
    changeis_del () {
      console.log(this.is_del);
      this.page = 1;
      this.getdata();
    },
    // TODO 显示编辑弹出框
    edit (val) {
      this.editobj = JSON.parse(JSON.stringify(val));
      this.editshow = true;
    },
    // TODO 执行编辑操作
    doedit () {
      const that = this;
      that.$axios.post('/fenzhi/update', this.editobj).then((res) => {
        console.log('修改分支机构返回', res);
        if (res.code === 200) {
          that.$message.success('修改成功');
          that.getdata();
          that.editshow = false;
        } else {
          that.$message.info(res.msg);
        }
      }).catch((err) => [
        console.log('请求失败', err),
      ]);
    },
    // TODO 显示新加弹出框
    add () {
      this.addobj = {
        heying: '',
        img: '',
        jianshao: '',
        lingdao: '',
        name: '',
        xuhao: 0,
      };
      this.addshow = true;
    },
    // TODO 执行新加操作
    doadd () {
      const that = this;
      that.$axios.post('/fenzhi/add', this.addobj).then((res) => {
        console.log('新加分支返回', res);
        if (res.code === 200) {
          that.$message.success('添加成功');
          that.page = 1;
          that.getdata();
          that.addshow = false;
        } else {
          that.$message.info(res.msg);
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.page = 1;
      this.getdata();
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getdata();
    },
    // TODO 获取数据
    getdata () {
      const that = this;
      this.$axios.post('/fenzhi/getList', {
        page: this.page,
        size: this.size,
        is_del: this.is_del,
      }).then((res) => {
        console.log('获取分支机构返回', res);
        if (res.code === 200) {
          that.tableData = res.data.data;
          that.page = res.data.page;
          that.size = res.data.size;
          that.total = res.data.count;
        } else {
          that.$message.error('获取数据失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 图片上传成功
    uploadsucess (res, file, fileList) {
      console.log('上传接口返回', res);
      console.log('文件', file);
      console.log('文件列表', fileList);
      if (res.code === 200) {
        if (this.addshow) {
          this.addobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log('新建内容', this.addobj);
        }
        if (this.editshow) {
          this.editobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log('编辑内容', this.editobj);
        }
      } else {
        this.$message.error('图片上传失败');
      }
    },
    // TODO 图片上传成功
    uploadsucess1 (res, file, fileList) {
      console.log('上传接口返回', res);
      console.log('文件', file);
      console.log('文件列表', fileList);
      if (res.code === 200) {
        if (this.addshow) {
          this.addobj.heying = `https://www.gdnos.com.cn/${res.msg}`;
          console.log('新建内容', this.addobj);
        }
        if (this.editshow) {
          this.editobj.heying = `https://www.gdnos.com.cn/${res.msg}`;
          console.log('编辑内容', this.editobj);
        }
      } else {
        this.$message.error('图片上传失败');
      }
    },
    // TODO 上传图片之前开启loading
    beforeUpload (file) {
      // this.uillUpdateImg = true
      this.$message.info('图片上传中，请等待。。。');
      const that = this;
      return new Promise((resolve) => {
        const reader = new FileReader();
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          let { width } = image;
          let { height } = image;
          // 等比缩放第一种 宽或高超过1280，进行等比例缩放
          let rate = image.width / image.height;
          if (width > 1280 || height > 1280) {
            width = rate > 1 ? 1280 : 1280 * rate;
            height = rate < 1 ? 1280 : 1280 / rate;
          }

          // 等比缩放第二种 宽或高超过1280，等比缩放
          rate = 1280 / width > 1;
          const tate = 1280 / height > 1;
          if (!rate) {
            const product = 1280 / width;
            width = Math.floor((width * product) * 100) / 100;
            height = Math.floor((height * product) * 100) / 100;
          } else if (!tate) {
            const product = 1280 / height;
            width = Math.floor((width * product) * 100) / 100;
            height = Math.floor((height * product) * 100) / 100;
          }
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type);
          const blobData = that.dataURItoBlob(dataUrl, file.type);
          resolve(blobData);
        };
        reader.onload = ((e) => { image.src = e.target.result; });
        reader.readAsDataURL(file);
      });
    },
    // TODO 压缩图片
    dataURItoBlob (dataURI, type) {
      const binary = atob(dataURI.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type });
    },
  },
};

</script>
<style scoped>
</style>
