<!-- 二维码组件 -->
<template>
  <div>
    <qrcode-vue :value="qrcodeUrl"
                :size="size"
                level="H"
                :background="background"
                :foreground="foreground"></qrcode-vue>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  props: {
    qrcodeUrl: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 100,
    },
    background: {
      type: String,
      default: '#ffffff',
    },
    foreground: {
      type: String,
      default: '#000000',
    },
  },
  data () {
    return {

    };
  },

  components: {
    QrcodeVue,
  },

  computed: {},

  mounted () { },

  methods: {},
};

</script>
<style scoped>
</style>
