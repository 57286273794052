<!-- 赛场视频 -->
<template>
  <div style="height:100vh;background-color: #ffc78e;width:94vw;overflow: auto;padding:0 3vw;">
    <div v-if="sign==1"
         style="text-align:left;">
      <div class="m_detail_box">
        <div class="m_detail_box_item_1">比赛日期</div>
        <div>{{showobj.bstime}}</div>
      </div>
      <div class="m_detail_box">
        <div class="m_detail_box_item_1">赛场</div>
        <div>{{showobj.saichang}}</div>
      </div>
      <div class="m_detail_box">
        <div class="m_detail_box_item_1">序号</div>
        <div>{{showobj.xuhao}}</div>
      </div>
      <div class="m_detail_box">
        <div class="m_detail_box_item_1">参赛者</div>
        <div>{{showobj.name}}</div>
      </div>
      <video-player v-if="showobj.yzshipin"
                    :src="showobj.yzshipin" />
    </div>
    <div v-if="sign==2"
         style="text-align:left;">
      <div class="m_detail_box">
        <div class="m_detail_box_item_1">比赛日期</div>
        <div>{{showobj.f_bstime}}</div>
      </div>
      <div class="m_detail_box">
        <div class="m_detail_box_item_1">赛场</div>
        <div>{{showobj.f_saichang}}</div>
      </div>
      <div class="m_detail_box">
        <div class="m_detail_box_item_1">序号</div>
        <div>{{showobj.f_xuhao}}</div>
      </div>
      <div class="m_detail_box">
        <div class="m_detail_box_item_1">参赛者</div>
        <div>{{showobj.name}}</div>
      </div>
      <video-player v-if="showobj.f_shipin"
                    :src="showobj.f_shipin" />
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/components/MyVideo.vue';

export default {
  data () {
    return {
      id: 0,
      sign: 0, // 1初赛2决赛
      showobj: {
        saichang: '',
        xuhao: '',
        name: '',
        img: '',
        yzshipin: false,
        f_shipin: false,
        f_bstime: '',
        f_qumu: '',
        f_saichang: '',
        f_xuhao: '',
      },
      // src: 'https://www.gdnos.com.cn/upload/shipin/2022/01/02/71b68783d7d0395b4b343e8419b85081.m3u8',
      // type: 'application/x-mpegURL',
      // src: 'https://wwwroot/www.gdnos.com.cn/upload/shipin/2022/01/15/2f4d53d94a2429e2572812bc009beb57.m3u8',
      // type: 'application/x-mpegURL',

    };
  },

  components: {
    VideoPlayer,
  },

  computed: {},

  mounted () {
    const obj = this.$route.query;
    if (obj.id && obj.sign) {
      this.id = parseInt(obj.id, 10);
      this.sign = parseInt(obj.sign, 10);
      if (this.id) {
        this.getdata();
      }
    }
  },

  methods: {
    // TODO 获取参赛视频数据
    getdata () {
      const that = this;
      that.$axios.post('/fusai/getSp', {
        id: this.id,
        sign: this.sign,
      }).then((res) => {
        console.log('参赛视频数据', res);
        if (res.code === 200 && res.data) {
          that.showobj = res.data;
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
  },
};

</script>
<style scoped>
.m_detail_box_item_1 {
  color: black;
  /* color: #d9ecff; */
  font-size: 5vw;
}
.m_detail_box {
  width: 88vw;
  padding: 2vw;
  padding-left: 4vw;
  margin: 2vw 0;
  background: white;
  border-radius: 5vw;
}
</style>
