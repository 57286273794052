<!-- 书籍 民乐报管理 -->
<template>
  <div>
    <div style="text-align:left;margin:5px 0;">
      <el-select v-model="bookid"
                 @change="search"
                 placeholder="请选择">
        <el-option v-for="(item,i) in booklist"
                   :key="i"
                   :label="item.name"
                   :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary"
                 style="margin:0 5px;"
                 @click="add">新加</el-button>
      <el-select v-model="is_del"
                 placeholder="请选择">
        <el-option v-for="(item,i) in statuslist"
                   :key="i"
                   :label="item.name"
                   :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary"
                 style="margin:0 5px;"
                 @click="search">查询</el-button>
    </div>
    <el-table :data="tableData"
              stripe
              border
              style="width: 100%">
      <el-table-column v-if="bookid!=1"
                       prop="year"
                       align="center"
                       label="年"
                       width="180">
      </el-table-column>
      <el-table-column v-if="bookid!=1"
                       prop="month"
                       align="center"
                       label="月"
                       width="180">
      </el-table-column>
      <el-table-column v-if="bookid!=1"
                       prop="bianhao"
                       align="center"
                       label="编号"
                       width="180">
      </el-table-column>
      <el-table-column prop="yeshu"
                       align="center"
                       label="页码"
                       width="180">
      </el-table-column>
      <el-table-column label="内容"
                       prop="img"
                       align="center"
                       width="300">
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px"
                    :src="scope.row.img"
                    fit="contain"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="update_time"
                       align="center"
                       label="修改时间"
                       width="180">
      </el-table-column>
      <el-table-column label="操作"
                       align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)"
                     type="primary"
                     size="small">编辑</el-button>
          <el-button v-if="scope.row.is_del===0"
                     type="danger"
                     @click="del(scope.row,1)"
                     size="small">隐藏</el-button>
          <el-button v-else
                     type="success"
                     @click="del(scope.row,0)"
                     size="small">显示</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="page"
                   :page-sizes="[20, 50, 100]"
                   :page-size="size"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total">
    </el-pagination>
    <!-- 新建 -->
    <el-dialog title="新建"
               :visible.sync="addshow"
               width="80%">
      <el-form ref="addobj"
               :model="addobj"
               label-width="100px"
               style="text-align:left;">
        <el-form-item v-if="addobj.bookid!=1"
                      label="年份">
          <el-date-picker v-model="addobj.year"
                          type="year"
                          value-format="yyyy"
                          placeholder="选择年">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="addobj.bookid!=1"
                      label="月份">
          <el-date-picker v-model="addobj.month"
                          type="month"
                          value-format="M"
                          placeholder="选择月">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="addobj.bookid!=1"
                      label="编号">
          <el-input v-model="addobj.bianhao"
                    placeholder="请输入编号"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-upload :action="uploadurl"
                     :headers="myHeaders"
                     name="file"
                     class="avatar-uploader"
                     :show-file-list="false"
                     :before-upload="beforeUpload"
                     :on-success="uploadsucess">
            <img v-if="addobj.img"
                 :src="addobj.img"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="页码">
          <el-input v-model="addobj.yeshu"
                    type="number"
                    placeholder="请输入页码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addshow = false">取 消</el-button>
        <el-button type="primary"
                   @click="doadd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="编辑"
               :visible.sync="editshow"
               width="80%">
      <el-form ref="editobj"
               :model="editobj"
               label-width="100px"
               style="text-align:left;">
        <el-form-item v-if="editobj.bookid!=1"
                      label="年份">
          <el-date-picker v-model="editobj.year"
                          type="year"
                          value-format="yyyy"
                          placeholder="选择年">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="editobj.bookid!=1"
                      label="月份">
          <el-date-picker v-model="editobj.month"
                          type="month"
                          value-format="M"
                          placeholder="选择月">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="editobj.bookid!=1"
                      label="编号">
          <el-input v-model="editobj.bianhao"
                    placeholder="请输入编号"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-upload :action="uploadurl"
                     :headers="myHeaders"
                     name="file"
                     class="avatar-uploader"
                     :show-file-list="false"
                     :before-upload="beforeUpload"
                     :on-success="uploadsucess">
            <img v-if="editobj.img"
                 :src="editobj.img"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="页码">
          <el-input v-model="editobj.yeshu"
                    type="number"
                    placeholder="请输入页码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="editshow = false">取 消</el-button>
        <el-button type="primary"
                   @click="doedit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      addshow: false,
      editshow: false,
      addobj: {
        bookid: '',
        img: '',
        yeshu: '',
        year: '',
        month: 0,
        bianhao: '',
      },
      editobj: {
        bookid: '',
        img: '',
        yeshu: '',
        year: '',
        month: 0,
        bianhao: '',
      },
      page: 1,
      size: 20,
      total: 0,
      myHeaders: { jwttoken: sessionStorage.getItem('jwt_token') },
      uploadurl: 'https://www.gdnos.com.cn/api/test/uploadimg',
      fileList: [],
      is_del: 0,
      statuslist: [{ id: 0, name: '显示' }, { id: 1, name: '隐藏' }],
      booklist: [],
      bookid: 1,
    };
  },

  components: {},

  computed: {},

  mounted () {
    this.page = 1;
    this.getdata();
    this.getbookdata();
  },

  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.page = 1;
      this.getdata();
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getdata();
    },
    // TODO 获取书籍列表
    getbookdata () {
      const that = this;
      this.$axios.post('/book/getlist', {
        page: 1,
        size: 5,
        is_del: 0,
      }).then((res) => {
        console.log('获取书籍列表返回', res);
        if (res.code === 200) {
          that.booklist = res.data.data;
        } else {
          that.booklist = [];
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 查询
    search () {
      this.page = 1;
      this.getdata();
    },
    // TODO 图片上传成功
    uploadsucess (res, file, fileList) {
      console.log('上传接口返回', res);
      console.log('文件', file);
      console.log('文件列表', fileList);
      if (res.code === 200) {
        if (this.addshow) {
          this.addobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log('新建内容', this.addobj);
        }
        if (this.editshow) {
          this.editobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log('编辑内容', this.editobj);
        }
      } else {
        this.$message.error('图片上传失败');
      }
    },
    // TODO 上传图片之前开启loading
    beforeUpload (file) {
      // this.uillUpdateImg = true
      this.$message.info('图片上传中，请等待。。。');
      const that = this;
      return new Promise((resolve) => {
        const reader = new FileReader();
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          let { width } = image;
          let { height } = image;
          // 等比缩放第一种 宽或高超过1280，进行等比例缩放
          let rate = image.width / image.height;
          if (width > 1280 || height > 1280) {
            width = rate > 1 ? 1280 : 1280 * rate;
            height = rate < 1 ? 1280 : 1280 / rate;
          }

          // 等比缩放第二种 宽或高超过1280，等比缩放
          rate = 1280 / width > 1;
          const tate = 1280 / height > 1;
          if (!rate) {
            const product = 1280 / width;
            width = Math.floor((width * product) * 100) / 100;
            height = Math.floor((height * product) * 100) / 100;
          } else if (!tate) {
            const product = 1280 / height;
            width = Math.floor((width * product) * 100) / 100;
            height = Math.floor((height * product) * 100) / 100;
          }
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type);
          const blobData = that.dataURItoBlob(dataUrl, file.type);
          resolve(blobData);
        };
        reader.onload = ((e) => { image.src = e.target.result; });
        reader.readAsDataURL(file);
      });
    },
    // TODO 压缩图片
    dataURItoBlob (dataURI, type) {
      const binary = atob(dataURI.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type });
    },
    // TODO 获取书籍内容数据
    getdata () {
      const that = this;
      this.$axios.post('/bookcontent/getlist', {
        page: this.page,
        size: this.size,
        is_del: this.is_del,
        bookid: this.bookid,
      }).then((res) => {
        console.log('获取书籍内容返回', res);
        if (res.code === 200) {
          that.tableData = res.data.data;
          that.total = res.data.count;
          that.size = res.data.size;
        } else {
          that.tableData = [];
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 新增书籍内容
    add () {
      console.log('新增书籍内容');
      if (parseInt(this.bookid, 10) === 1) {
        this.addobj = {
          bookid: this.bookid,
          img: '',
          yeshu: 1,
          year: '',
          month: '',
          bianhao: '',
        };
      } else {
        this.addobj = {
          bookid: this.bookid,
          img: '',
          yeshu: 1,
          year: this.$moment().format('YYYY'),
          month: this.$moment().format('M'),
          bianhao: '',
        };
      }
      this.addshow = true;
    },
    // TODO 执行新增书籍内容
    doadd () {
      if (!this.addobj.bookid) {
        this.$message.info('请选择书籍');
        return;
      }
      if (!this.addobj.img) {
        this.$message.info('请上传内容');
        return;
      }
      if (!this.addobj.yeshu) {
        this.$message.info('请输入页数');
        return;
      }
      if (parseInt(this.bookid, 10) !== 1 && !this.addobj.year) {
        this.$message.info('请输入年份');
        return;
      }
      if (parseInt(this.bookid, 10) !== 1 && !this.addobj.month) {
        this.$message.info('请输入月份');
      }
      this.addobj.yeshu = parseInt(this.addobj.yeshu, 10);
      const that = this;
      that.$axios.post('/bookcontent/add', this.addobj).then((res) => {
        console.log('添加书籍内容返回', res);
        if (res.code === 200) {
          that.$message.success('添加内容成功');
          that.page = 1;
          that.is_del = 0;
          that.getdata();
          that.addshow = false;
        } else {
          that.$message.error('添加内容失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 编辑书籍内容
    edit (data) {
      console.log('编辑内容', data);
      this.editobj = JSON.parse(JSON.stringify(data));
      this.editshow = true;
    },
    // TODO 执行编辑
    doedit () {
      if (!this.editobj.bookid) {
        this.$message.info('请选择书籍');
        return;
      }
      if (!this.editobj.img) {
        this.$message.info('请上传内容');
        return;
      }
      if (!this.editobj.yeshu) {
        this.$message.info('请输入页数');
        return;
      }
      if (parseInt(this.bookid, 10) !== 1 && !this.editobj.year) {
        this.$message.info('请输入年份');
        return;
      }
      if (parseInt(this.bookid, 10) !== 1 && !this.editobj.month) {
        this.$message.info('请输入月份');
      }
      this.editobj.yeshu = parseInt(this.editobj.yeshu, 10);
      const that = this;
      that.$axios.post('/bookcontent/update', this.editobj).then((res) => {
        console.log('编辑内容返回', res);
        if (res.code === 200) {
          that.$message.success('编辑内容成功');
          that.getdata();
          that.editshow = false;
        } else {
          that.$message.error('编辑内容失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
    // TODO 隐藏显示书籍内容
    del (data, sign) {
      console.log(`隐藏显示内容${sign}`, data);
      const obj = JSON.parse(JSON.stringify(data));
      obj.is_del = sign;
      const that = this;
      that.$axios.post('/bookcontent/del', obj).then((res) => {
        console.log('内容显示状态返回', res);
        if (res.code === 200) {
          that.$message.success('内容显示状态修改成功');
          that.getdata();
        } else {
          that.$message.error('内容显示状态修改失败');
        }
      }).catch((err) => {
        console.log('请求失败', err);
      });
    },
  },
};

</script>
<style scoped>
</style>
