<template>
  <div style="display:inline-block;">
    <el-tooltip class="item"
                effect="dark"
                :content="msg"
                placement="top">
      <el-upload action="https://jsonplaceholder.typicode.com/posts/"
                 ref="upload"
                 :limit="1"
                 accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                 :auto-upload="false"
                 :on-change="exportData"
                 :file-list="fileList">
        <el-button type="primary">{{title}}</el-button>
      </el-upload>
    </el-tooltip>
  </div>
</template>

<script>
import XLSX from 'xlsx';

export default {
  data () {
    return {
      fileList: [],
    };
  },
  name: 'importExcelCommon',
  props: {
    title: {
      type: String,
      default: '导入表格',
    },
    msg: {
      type: String,
      default: '提示信息',
    },
  },
  methods: {
    // TODO 获取excel文件
    exportData (file) {
      const that = this;
      console.log('获取excel文件', file.raw);
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // 取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); // 生成json表格内容
          // 从解析出来的数据中提取相应的数据
          // ws.forEach((item) => {
          //   console.log('excel', item);
          // });
          that.$emit('getResult', ws);
          that.fileList = [];
        } catch (err) {
          console.log('报错', err);
        }
      };
      fileReader.readAsBinaryString(file.raw);
    },
  },
};
</script>

<style scoped>
.input-file {
  display: none;
}
.content {
  margin-right: 5px;
}
</style>
